import { StatusConfirmationCode } from "~/typings/types";
import { getCountOfStepsInAnketa } from "~/utils";

export const KEY_CUSTOMER_CLIENT_ID = "clientId";
export const KEY_CUSTOMER_CLIENT_ID_V2 = "clientId_v2";
export const KEY_PARTNER_CLIENT_ID = "partnerClientId";
export const KEY_CLIENT_HASH = "hash";
export const BUTTON_NEXT_ID = "button_next_id";
export const BUTTON_BACK_ID = "button_back_id";
export const CLIENT_PROMOCODE = "client_promocode";
export const REFERRAL_PROMOCODE = "promocode_referral";
export const ERROR_FIELD_ = "error_field_";
export const INITIAL_REQUEST_CODE: StatusConfirmationCode = "initial-request-code";
export const PENDING_REQUEST_CODE: StatusConfirmationCode = "pending-request-code";
export const RESEND_REQUEST_CODE: StatusConfirmationCode = "resend-request-code";
export const SEND_WITHOUT_REQUEST_CODE: StatusConfirmationCode = "send-without-request-code";
export const ATTACHMENT_PAGE = "attachment-page";
export const REACT_APP_FEATURE_FLAG = "REACT_APP_FEATURE_FLAG";
export const KEY_SMS_CONFIRMATION_FEATURE_FLAG = "sms-confirmation";
export const IS_ENABLED_YANDEX_METRIKA = import.meta.env.VITE_IS_ENABLED_YANDEX_METRIKA;

export const URL_OFERTA = "https://prodamus.ru/oferta";
export const URL_PRIVACY = "https://prodamus.ru/privacy";
export const URL_TARIF = "https://prodamus.ru/tarif";