import React, { useState } from "react";
import PhoneInput, { CountryData, PhoneInputProps } from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import { FieldRenderProps } from "react-final-form";
import { configStylesByState, StateStyles } from "./configStylesByState";
import { ERROR_FIELD_ } from "~/configs/consts";
import cn from "classnames";

import styles from "./PhoneField.module.scss";

interface PhoneFieldProps extends FieldRenderProps<string, HTMLElement>, Partial<PhoneInputProps> {
  isAutoFocus?: boolean;
  orderNumber?: string;
  onlyCountries?: string[];
}

export const PhoneField: React.FC<PhoneFieldProps> = ({
  input,
  meta,
  isAutoFocus = false,
  orderNumber,
  disabled = false,
  onlyCountries,
  ...props
}) => {
  const [countryInfo, setCountryInfo] = useState<CountryData | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  // const hasError = meta?.error || (meta.touched || meta.submitFailed);
  const hasError = (meta?.error || meta?.submitError) && meta?.submitFailed

  const inputId = hasError ? `${ERROR_FIELD_ + orderNumber}` : undefined;

  const getStateStyles = (): StateStyles => {
    if (hasError && isFocused) return StateStyles.ErrorFocused;
    if (hasError && isHovered) return StateStyles.ErrorHovered;
    if (hasError) return StateStyles.Error;
    if (isFocused) return StateStyles.Focused;
    if (isHovered) return StateStyles.Hover;
    if (disabled) return StateStyles.Disabled;
    return StateStyles.Default;
  };

  const stateStyles = getStateStyles();

  const containerStyle: React.CSSProperties = {
    ...configStylesByState[StateStyles.Default].containerStyle,
    ...configStylesByState[stateStyles].containerStyle,
  };

  const inputStyle: React.CSSProperties = {
    ...configStylesByState[StateStyles.Default].inputStyle,
    ...configStylesByState[stateStyles].inputStyle,
  };

  const handleCountryChange = (value: string, info: CountryData) => {
    setCountryInfo(info);
    input.onChange(value);
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      id={hasError ? `${ERROR_FIELD_ + orderNumber}` : undefined}
    >
      <PhoneInput
        {...props}
        disabled={disabled}
        value={input.value}
        onChange={handleCountryChange}
        localization={ru}
        onlyCountries={onlyCountries}
        country="ru"
        masks={{ ci: ".........." }}
        specialLabel={""}
        areaCodes={{ kz: [7, 6] }}
        inputProps={{
          name: input.name,
          id: inputId,
          autoFocus: isAutoFocus,
          onBlur: (e: any) => {
            setIsFocused(false);
            input.onBlur(e);
          },
          onFocus: () => setIsFocused(true),
        }}
        inputStyle={inputStyle}
        containerStyle={containerStyle}
        inputClass={cn({
          [styles.hasErrorContainer]: hasError,
          [styles.hoveredContainer]: isHovered,
          [styles.errorFocusedContainer]: hasError && isFocused,
        })}
      />
    </div>
  );
};
