import React, { useState } from "react";
import { DatePicker, DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FieldRenderProps } from "react-final-form";
import { configStylesByState, StateStyles } from "./configStylesByState";
import { ERROR_FIELD_ } from "~/configs/consts";
import cn from "classnames";

import styles from "./DatePickerField.module.scss";

interface DatePickerFieldProps extends FieldRenderProps<string, HTMLElement>, Partial<DatePickerProps> {
  isAutoFocus?: boolean;
  orderNumber?: number | string;
}

export const DatePickerField: React.FC<DatePickerFieldProps> = ({
  input,
  meta,
  isAutoFocus = false,
  orderNumber,
  disabled = false,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const hasError = meta?.error && (meta.touched || meta.submitFailed);

  const getStateStyles = (): StateStyles => {
    if (hasError && isFocused) return StateStyles.ErrorFocused;
    if (hasError) return StateStyles.Error;
    if (isFocused) return StateStyles.Focused;
    if (isHovered) return StateStyles.Hover;
    if (disabled) return StateStyles.Disabled;
    return StateStyles.Default;
  };

  const stateStyles = getStateStyles();

  const containerStyle: React.CSSProperties = {
    ...configStylesByState[StateStyles.Default].containerStyle,
    ...configStylesByState[stateStyles].containerStyle,
  };

  const inputStyle: React.CSSProperties = {
    ...configStylesByState[StateStyles.Default].inputStyle,
    ...configStylesByState[stateStyles].inputStyle,
  };

  const handleChange = (date: Dayjs) => {
    input.onChange(date ? date.format("YYYY-MM-DD") : '');
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={containerStyle}
      id={hasError ? `${ERROR_FIELD_ + orderNumber}` : undefined}
      className={cn({
        [styles.hasErrorContainer]: hasError,
        [styles.hoveredContainer]: isHovered,
        [styles.errorFocusedContainer]: hasError && isFocused,
      })}
    >
      <DatePicker
        {...props}
        placeholder={isFocused ? dayjs().format("DD.MM.YYYY") : ""}
        disabled={disabled}
        format={"DD.MM.YYYY"}
        value={input.value ? dayjs(input.value) : null}
        onChange={handleChange}
        onBlur={() => {
          setIsFocused(false);
          input.onBlur();
        }}
        onFocus={() => setIsFocused(true)}
        style={inputStyle}
        disabledDate={props.disabledDate}
        defaultPickerValue={props.defaultPickerValue} 
      />
    </div>
  );
};
