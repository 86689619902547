import { useEffect, useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { TitlePage, PhoneField, CustomButton, Icon, DatePickerField } from "~/components";
import { composeValidators, disabledValidate, notEmptyValue, required, testRegExp, validateDependentFields, validateRelatedFields } from "~/utils/validate";
import { Divider, message } from "antd";
import { FormField, FormFieldAutocompleteAddress, FormFieldAutocomplete } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum, OrgTypeEnum, TypeSuggestionData } from "~/typings/types";
import { configTitleFieldsOfSelfEmployed } from "../configTitleFields";
import cn from "classnames";
import { getCrmNameByOrgType } from "../../../../shared/utils/getCrmNameByOrgType";
import { V2Wizard } from "../../../../shared/wizard";
import { FullWidthRowFormContainer } from "~/pages/questionnaires/shared/form-field";
import { PERSONAL_DATA_ADDRESS_SUGGEST, PERSONAL_DATA_FULLNAME_SUGGEST } from "../../../../api/apiUrls";
import dayjs, { Dayjs } from "dayjs";
import { RenderError } from "~/components/error-field";

import styles from "./CommonSteps.module.scss";
import '../../styles.scss';

interface AccountDetailsProps {
  clientId: string;
  isShowAdditionalFieldsInitially?: boolean;
}

export const AccountDetails = ({
  clientId,
  isShowAdditionalFieldsInitially = false,
}: AccountDetailsProps) => {
  const [showAdditionalFields, setShowAdditionalFields] = useState(isShowAdditionalFieldsInitially);

  const toggleAdditionalFields = () => setShowAdditionalFields(!showAdditionalFields);

  return (
    <V2Wizard.Page>
      <TitlePage
        name="Регистрация профиля в системе"
        number="2"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FullWidthRowFormContainer>
          <FormSpy>
            {propsSpy => {
              const errorFullname = propsSpy?.submitErrors?.[NamesOfFieldEnum.CrmNameFirst]
                || propsSpy?.submitErrors?.[NamesOfFieldEnum.CrmNameLast]
                || propsSpy?.submitErrors?.[NamesOfFieldEnum.CrmNamePatron];

              const renderErrorFullname: RenderError = {
                has:
                  Boolean(errorFullname) && ((propsSpy.values?.[NamesOfFieldEnum.CrmNameFirst] && !propsSpy.values?.[NamesOfFieldEnum.CrmNameLast]) ||
                    (!propsSpy.values?.[NamesOfFieldEnum.CrmNameFirst] && propsSpy.values?.[NamesOfFieldEnum.CrmNameLast]))
                    ? 1
                    : 0,
                message: errorFullname
              };

              return (
                <FormFieldAutocomplete
                  titleField={getCrmNameByOrgType(OrgTypeEnum.SelfEmployed)}
                  name={NamesOfFieldEnum.CrmName}
                  keyValueAutocompleteSelected={("name" as keyof TypeSuggestionData)}
                  required
                  validate={composeValidators(required(), notEmptyValue(),
                    validateRelatedFields(
                      [NamesOfFieldEnum.CrmNameLast, NamesOfFieldEnum.CrmNameFirst],
                      "Укажите фамилию, имя и отчество через пробел и выберите из списка"
                    )
                  )}
                  propsSpy={propsSpy}
                  clientId={clientId}
                  urlPersonalDataAddressSuggest={PERSONAL_DATA_FULLNAME_SUGGEST}
                  handleInputChangeFromProps={() => {
                    propsSpy.form.change(NamesOfFieldEnum.CrmNameFirst, '')
                    propsSpy.form.change(NamesOfFieldEnum.CrmNameLast, '')
                    propsSpy.form.change(NamesOfFieldEnum.CrmNamePatron, '')
                  }}
                  renderError={renderErrorFullname}
                  handleSuggestionClickFromProps={(suggestion) => {
                    if ('name' in suggestion) {
                      suggestion?.first_name && propsSpy.form.change(NamesOfFieldEnum.CrmNameFirst, suggestion.first_name);
                      suggestion?.last_name && propsSpy.form.change(NamesOfFieldEnum.CrmNameLast, suggestion.last_name);
                      suggestion?.patronymic_name && propsSpy.form.change(NamesOfFieldEnum.CrmNamePatron, suggestion.patronymic_name);
                    }
                  }}
                  orderNumber="1"
                />
              );
            }}
          </FormSpy>
        </FullWidthRowFormContainer>

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.CrmPhone]}
          name={NamesOfFieldEnum.CrmPhone}
          required
        >
          <Field
            name={NamesOfFieldEnum.CrmPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            component={props => <PhoneField orderNumber="2" {...props} />}
            validate={disabledValidate(composeValidators(required()))}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.CrmEmail]}
          name={NamesOfFieldEnum.CrmEmail}
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^\s*\S+@\S+\.\S+\s*$/,
              "Пожалуйста, укажите корректный адрес электронной почты"
            )
          )}
          orderNumber="3"
        />

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.CrmBirthday]}
          name={NamesOfFieldEnum.CrmBirthday}
          required
        >
          <Field
            name={NamesOfFieldEnum.CrmBirthday}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            validate={disabledValidate(composeValidators(required()))}
            component={({ input, meta }) => (
              <DatePickerField
                showNow={false}
                input={input}
                meta={meta}
                orderNumber="4"
                defaultPickerValue={dayjs().subtract(18, "years")} 
                disabledDate={(current: Dayjs | null): boolean => {
                  const today = dayjs();
                  const eighteenYearsAgo = today.subtract(18, "years");
                  return current ? current.isAfter(eighteenYearsAgo) : false;
                }}
              />
            )}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.Inn]}
          name={NamesOfFieldEnum.Inn}
          required
          maxLength={12}
          validate={composeValidators(
            required(),
            testRegExp(/^(\d){12}$/g, "ИНН должен состоять из 12 цифр")
          )}
          orderNumber="5"
        />

        <FullWidthRowFormContainer>
          <FormSpy>
            {propsSpy => {
              return (
                <FormFieldAutocompleteAddress
                  titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.LegalAddress]}
                  name={NamesOfFieldEnum.LegalAddress}
                  nameFiasId={NamesOfFieldEnum.LegalAddressFiasId}
                  required
                  validate={composeValidators(required(), notEmptyValue())}
                  orderNumber="6"
                  propsSpy={propsSpy}
                  clientId={clientId}
                  urlPersonalDataAddressSuggest={PERSONAL_DATA_ADDRESS_SUGGEST}
                />
              );
            }}
          </FormSpy>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormSpy>
            {propsSpy => {
              return (
                <FormFieldAutocompleteAddress
                  titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.PostalAddress]}
                  name={NamesOfFieldEnum.PostalAddress}
                  nameFiasId={NamesOfFieldEnum.PostalAddressFiasId}
                  tooltip="При необходимости направим оригиналы документов на этот адрес"
                  orderNumber="7"
                  propsSpy={propsSpy}
                  clientId={clientId}
                  required
                  validate={composeValidators(required(), notEmptyValue())}
                  triggerAutofill={[NamesOfFieldEnum.PostalAddress, NamesOfFieldEnum.LegalAddress]}
                  triggerAutofillForAddressFiasId={[
                    NamesOfFieldEnum.PostalAddressFiasId,
                    NamesOfFieldEnum.LegalAddressFiasId
                  ]}
                  urlPersonalDataAddressSuggest={PERSONAL_DATA_ADDRESS_SUGGEST}
                />
              );
            }}
          </FormSpy>
        </FullWidthRowFormContainer>

        <Divider className={styles.fullWidthRow} style={{ marginTop: 0 }} />

        <CustomButton
          type="button"
          name={'Дополнительные контакты'}
          id="additional-contacts"
          onClick={toggleAdditionalFields}
          icon={showAdditionalFields ? <Icon name="down-arrow" className={cn(styles.iconDownArrow, styles.iconUpArrow)} /> : <Icon name="down-arrow" className={cn(styles.iconDownArrow)} />}
          className={cn(styles.toggleButton, styles.fullWidthRow)}
        />

        <FullWidthRowFormContainer>
          <p className={styles.additionalDesc} style={{ marginTop: 8 }}>Рекомендуем заполнить данные для оперативной связи по вопросам.</p>
          <p className={styles.additionalDesc}>При оставлении полей пустыми мы автоматически заполним их вашими контактами.</p>
        </FullWidthRowFormContainer>

        <div className={cn(styles.customCollapse, styles.fullWidthRow, { [styles.expanded]: showAdditionalFields })}>
          <div className={styles.collapseContent}>

            <FormField
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.BuhName]}
              name={NamesOfFieldEnum.BuhName}
              tooltip="Укажите контактные данные лица, которое принимает решения по финансовым вопросам проекта"
              orderNumber="8"
              triggerAutofill={[NamesOfFieldEnum.BuhName, NamesOfFieldEnum.CrmName]}
            />

            <FormField
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.BuhPhone]}
              name={NamesOfFieldEnum.BuhPhone}
              triggerAutofill={[NamesOfFieldEnum.BuhPhone, NamesOfFieldEnum.CrmPhone]}
            >
              <Field
                name={NamesOfFieldEnum.BuhPhone}
                subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
                component={props => <PhoneField orderNumber="9" {...props} />}
              />
            </FormField>

            <FormField
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.TechName]}
              name={NamesOfFieldEnum.TechName}
              tooltip="Укажите контактные данные лица, которое принимает решения по техническим вопросам проекта"
              orderNumber="10"
              triggerAutofill={[NamesOfFieldEnum.TechName, NamesOfFieldEnum.CrmName]}
            />

            <FormField
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.TechPhone]}
              name={NamesOfFieldEnum.TechPhone}
              triggerAutofill={[NamesOfFieldEnum.TechPhone, NamesOfFieldEnum.CrmPhone]}
            >
              <Field
                name={NamesOfFieldEnum.TechPhone}
                subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
                component={props => <PhoneField orderNumber="11" {...props} />}
              />
            </FormField>
          </div>
        </div>
      </div>
    </V2Wizard.Page>
  );
};
