type InitValues = Record<string, any>;

export const extractExistingFields = (
	fields: string[],
	initValues: InitValues
): Record<string, any> => {
	return fields.reduce((acc, field) => {
		if (initValues[field] !== undefined && initValues[field] !== null) {
			acc[field] = initValues[field];
		}
		return acc;
	}, {} as Record<string, any>);
};
