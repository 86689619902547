import React, { useEffect, useState } from "react";
import { SelfEmployedStep, IndividualEntrepreneurStep, CompanyStep } from "./steps";
import { FirstStep } from "./first-step";
import {
  useQuery,
  useScrollToErrorElement,
  useSharedDataFromBackend,
  useGetDraftPreviewId
} from "~/hooks";
import { OrgTypeEnum, NamesOfFieldEnum } from "~/typings/types";
import { CLIENT_PROMOCODE, KEY_CUSTOMER_CLIENT_ID } from "~/configs/consts";
import { trimUrl } from "~/utils/trimUrl";
import { clientApi, getKeyClientId } from "~/utils";
import { CHECK_PAYFORM_EXISTS, DRAFT_SAVE } from "../api/apiUrls";

const configSteps: Record<string, JSX.Element> = {
  [OrgTypeEnum.IndividualEntrepreneur]: <IndividualEntrepreneurStep />,
  [OrgTypeEnum.Company]: <CompanyStep />,
  [OrgTypeEnum.SelfEmployed]: <SelfEmployedStep />
};

export const CustomerQuestionnairePage = () => {
  const [orgType, setOrgType] = useState(OrgTypeEnum.SelfEmployed);
  const [clientId, setClientId] = useState<string | null>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isReturnedFirstStep, setIsReturnedFirstStep] = useState(false);
  const [isRenderSuccessPage, setIsRenderSuccessPage] = useState(false);
  const [isRenderErrorPage, setIsRenderErrorPage] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const [typeRequestCheckPayformName, setTypeRequestCheckPayformName] = useState<any>("init");
  const query = useQuery();
  const queryPromocode = query.get(CLIENT_PROMOCODE);

  useScrollToErrorElement();

  const { paymentMethod, educationalPlatform, productType } = useSharedDataFromBackend();

  useEffect(() => {
    const itemClientId = localStorage.getItem(getKeyClientId());
    const storedClientId = itemClientId ? JSON.parse(itemClientId) : null;

    setClientId(storedClientId);
  }, [localStorage.getItem(getKeyClientId())]);

  useGetDraftPreviewId({
    setFormData,
    setOrgType,
    setCurrentStep,
    setIsSubmit,
    setIsReturnedFirstStep,
    isReturnedFirstStep
  });

  const onResetData = () => {
    setIsSubmit(false);
    setFormData({});
    setCurrentStep(1);

    localStorage.removeItem(KEY_CUSTOMER_CLIENT_ID);
    window.location.href = "/";
  };

  const callbackCheckPayformName = async (payformName: any) => {
    try {
      const payform_name = payformName + ".payform.ru";
      const data = { payform_name };
      setTypeRequestCheckPayformName("loading");
      const response = await clientApi.post(CHECK_PAYFORM_EXISTS, data);
      const isSuccessResponse = response.data.exists !== 1;
      if (isSuccessResponse) {
        setTypeRequestCheckPayformName("success");
      } else {
        setTypeRequestCheckPayformName("error");
      }
    } catch (error: any) {
      if (error?.code === "ERR_NETWORK" || error?.code === "ERR_BAD_REQUEST") {
        setTypeRequestCheckPayformName("errorValidateFromApi"); // Костыль, надо на беке фиксануть ответ ошибки если падает валидация
        return;
      }
      setTypeRequestCheckPayformName("error");
    }
  };

  const handleSaveDraft = async () => {
    try {
      await clientApi.post(DRAFT_SAVE, {
        id: clientId
      });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const sharedStepProps = {
    initialValues: {
      [NamesOfFieldEnum.IsWantToBecomePartner]: "no",
      [NamesOfFieldEnum.LogoFileId]: [],
      [NamesOfFieldEnum.PassportFileId]: [],
      [NamesOfFieldEnum.PassportMainFileId]: [],
      [NamesOfFieldEnum.PassportRegistrationFileId]: [],
      [NamesOfFieldEnum.DocumentAgreement1FileId]: [],
      [NamesOfFieldEnum.DocumentAgreement2FileId]: [],
      ...formData.flat,
      [NamesOfFieldEnum.OrgType]: orgType,
      [NamesOfFieldEnum.ClientPromocode]:
        queryPromocode || formData.flat?.[NamesOfFieldEnum.ClientPromocode],
      [NamesOfFieldEnum.PayformName]: formData.flat?.[NamesOfFieldEnum.PayformName]
        ? trimUrl(formData.flat[NamesOfFieldEnum.PayformName])
        : ""
    },
    onSubmit: handleSaveDraft,
    setIsSubmit,
    currentStep,
    setCurrentStep,
    clientId,
    orgType,
    setOrgType,
    setIsReturnedFirstStep,
    onResetData,
    paymentMethod,
    educationalPlatform,
    queryPromocode,
    productType,
    isRenderSuccessPage,
    setIsRenderSuccessPage,
    isRenderErrorPage,
    setIsRenderErrorPage,
    typeRequestCheckPayformName,
    setTypeRequestCheckPayformName,
    callbackCheckPayformName
  };

  return (
    <>
      {isSubmit && orgType ? (
        React.cloneElement(configSteps[orgType], sharedStepProps)
      ) : (
        <FirstStep
          orgType={orgType}
          setOrgType={setOrgType}
          setIsSubmit={setIsSubmit}
          setFormData={setFormData}
          onResetData={onResetData}
        />
      )}
    </>
  );
};
