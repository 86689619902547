import React, { ReactNode, type FC, useState } from "react";
import { Field } from "react-final-form";
import styles from "./FormField.module.scss";
import { AutofillFieldButton, DescriptionCollapseField, ErrorField, InputField, TitleField } from "~/components";
import { disabledValidate } from "~/utils/validate";
import { TypeInput } from "~/typings/types";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import cn from "classnames";

interface Props {
  name: string;
  titleField?: string;
  descriptionField?: ReactNode;
  mask?: string;
  typeField?: string;
  validate?: any;
  required?: boolean;
  tooltip?: string;
  isColon?: boolean;
  showCount?: boolean;
  styleContainerTitleField?: any;
  styleContainer?: any;
  placeholder?: string;
  typeInput?: TypeInput;
  autoSize?: any;
  maxLength?: number;
  children?: React.ReactNode;
  disabled?: boolean;
  addonBefore?: string;
  addonAfter?: string;
  renderError?: any;
  orderNumber?: string;
  triggerAutofill?: string[];
  onBlur?: any;
  typeRequestCheckPayformName?: any;
  placement?: TooltipPlacement;
  isFullWidthRow?: boolean;
}

const removeSpaces = (value: string) => value && value?.trim();
const antIcon = <LoadingOutlined className={styles.spin} spin />;

const configStatusPayformName: any = {
  loading: {
    has: 1,
    message: "Проверка названия платежной страницы",
    color: "grey"
  },
  success: {
    has: 1,
    message: "Данное название платежной страницы свободно",
    color: "#437a54"
  },
  error: {
    has: 1,
    message: "Данное название платежной страницы занято",
    color: "#E41D36"
  },
  errorValidateFromApi: {
    has: 1,
    message: `Только англ. буквы, цифры и знак "-". Не более 25 символов`,
    color: "#E41D36"
  }
};

export const FormField: FC<Props> = ({
  typeField = "text",
  children,
  name,
  titleField,
  descriptionField,
  validate,
  required,
  tooltip,
  isColon,
  showCount,
  styleContainerTitleField,
  styleContainer,
  placeholder,
  typeInput,
  autoSize,
  maxLength,
  mask,
  disabled,
  addonBefore,
  addonAfter,
  renderError,
  orderNumber,
  triggerAutofill,
  onBlur,
  typeRequestCheckPayformName,
  placement,
  isFullWidthRow // Работает только для customer v2 questionare. можно расположить как в 1 так и 2 столбца филды на одной строке
}) => {
  const isLoadingCheckPayformName = typeRequestCheckPayformName === "loading";
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={cn(styles.containerField, {
      [styles.fullWidthRow]: isFullWidthRow
    })} style={styleContainer}>
      {titleField && (
        <TitleField
          name={titleField}
          required={required}
          tooltip={tooltip}
          isColon={isColon}
          styleContainer={styleContainerTitleField}
          placement={placement}
        />
      )}
      <DescriptionCollapseField content={descriptionField} trigger={isFocused} />
      {children || (
        <Field
          name={name}
          component="input"
          type={typeField}
          validate={disabledValidate(validate)}
          formatOnBlur
          format={removeSpaces}
        >
          {({ input, meta }) => (
            <div className={styles.containerInput}>
              <InputField
                {...input}
                mask={mask}
                meta={meta}
                placeholder={placeholder}
                typeInput={typeInput}
                autoSize={autoSize}
                maxLength={maxLength}
                disabled={disabled}
                addonBefore={addonBefore}
                addonAfter={addonAfter}
                showCount={showCount}
                orderNumber={orderNumber}
                onBlur={onBlur}
                typeRequestCheckPayformName={typeRequestCheckPayformName}
                onFocus={(e: any) => {
                  setIsFocused(true);
                  input.onFocus(e)
                }}
              />
              {isLoadingCheckPayformName && (
                <Spin indicator={antIcon} className={styles.containerSpin} />
              )}
            </div>
          )}
        </Field>
      )}
      <AutofillFieldButton triggerAutofill={triggerAutofill} />
      <ErrorField
        name={name}
        renderError={renderError || configStatusPayformName[typeRequestCheckPayformName]}
      />
    </div>
  );
};
