import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Layout } from "~/components/layout";
import {
  ErrorPage,
  EmailSuccessPage,
  EmailFailedPage,
  CustomerQuestionnairePage,
  PartnerQuestionnairePage,
  V2CustomerQuestionnairePage
} from "~/pages";
import { LocaleContainer, SentryContrainer } from "~/containers";
import { routes } from "./configs/routes";

import "~/assets/styles/style.scss";
import "~/assets/icons/index";

const PreserveQueryNavigate = ({ to }: { to: string }) => {
  const location = useLocation();
  const search = location.search;

  return <Navigate replace to={`${to}${search}`} />;
};

export const App = (): JSX.Element => {
  return (
    <SentryContrainer>
      <LocaleContainer>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/" element={<PreserveQueryNavigate to={routes.v2customer} />} />
              {/* <Route path={routes.customer} element={<CustomerQuestionnairePage />} /> */}
              <Route path={routes.v2customer} element={<V2CustomerQuestionnairePage />} />
              <Route path={routes.partner} element={<PartnerQuestionnairePage />} />
              <Route path={routes.emailSuccess} element={<EmailSuccessPage />} />
              <Route path={routes.emailFailed} element={<EmailFailedPage />} />
              <Route path="*" element={<Navigate to={routes.v2customer} replace />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </LocaleContainer>
    </SentryContrainer>
  );
};
