import { Dispatch, SetStateAction } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Spin } from "antd";
import cn from "classnames";
import { TitlePage } from "~/components";
import {
  RulesFiles,
  FormField,
  Wizard,
  UploadFile,
  AcceptPolitics
} from "~/pages/questionnaires/shared";
import { AttachmentsDetailsError, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";
import { configTitleFieldsOfSelfEmployed } from "../configTitleFields";

import "~/pages/questionnaires/shared/styles.css";
import styles from "~/pages/questionnaires/shared/Shared.module.scss";

interface Props {
  isLoadingMount: boolean;
  isLoadingLogo: boolean;
  isLoadingPassport: boolean;
  logoFiles: File[];
  setLogoFileId: Dispatch<SetStateAction<number[]>>;
  setLogoFiles: Dispatch<File[]>;
  passportFiles: File[];
  setPassportFileId: Dispatch<SetStateAction<number[]>>;
  setPassportFiles: Dispatch<File[]>;
  logoError: AttachmentsDetailsError;
  passportError: AttachmentsDetailsError;
  setLogoError: Dispatch<AttachmentsDetailsError>;
  setPassportError: Dispatch<AttachmentsDetailsError>;
  COUNT_UPLOAD_LOGO_FILE?: number;
  COUNT_UPLOAD_PASSPORT_FILE?: number;
  isAcceptPolitics: boolean;
  setIsAcceptPolitics: Dispatch<SetStateAction<boolean>>;
  acceptPoliticsError: AttachmentsDetailsError;
  setAcceptPoliticsError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  setIsFirstLoadingFile: Dispatch<boolean>;
  orgType?: OrgTypeEnum;
  COUNT_DOCUMENT_AGREEMENT1_FILE?: number;
  COUNT_DOCUMENT_AGREEMENT2_FILE?: number;
  isLoadingDocumentAgreement1File: boolean;
  documentAgreement1Files: File[];
  setDocumentAgreement1FileId: Dispatch<SetStateAction<number[]>>;
  setDocumentAgreement1Files: Dispatch<File[]>;
  documentAgreement1Error: AttachmentsDetailsError;
  setDocumentAgreement1Error: Dispatch<AttachmentsDetailsError>;
  isLoadingDocumentAgreement2File: boolean;
  documentAgreement2Files: File[];
  setDocumentAgreement2FileId: Dispatch<SetStateAction<number[]>>;
  setDocumentAgreement2Files: Dispatch<File[]>;
  documentAgreement2Error: AttachmentsDetailsError;
  setDocumentAgreement2Error: Dispatch<AttachmentsDetailsError>;

}

export const AttachmentsDetails = ({
  isLoadingMount,
  isLoadingLogo,
  isLoadingPassport,
  logoFiles,
  setLogoFileId,
  setLogoFiles,
  passportFiles,
  setPassportFileId,
  setPassportFiles,
  logoError,
  passportError,
  setLogoError,
  setPassportError,
  COUNT_UPLOAD_LOGO_FILE,
  COUNT_UPLOAD_PASSPORT_FILE,
  isAcceptPolitics,
  setIsAcceptPolitics,
  acceptPoliticsError,
  setAcceptPoliticsError,
  setIsFirstLoadingFile,
  orgType,
  COUNT_DOCUMENT_AGREEMENT1_FILE,
  COUNT_DOCUMENT_AGREEMENT2_FILE,
  isLoadingDocumentAgreement1File,
  documentAgreement1Files,
  setDocumentAgreement1FileId,
  setDocumentAgreement1Files,
  documentAgreement1Error,
  setDocumentAgreement1Error,
  isLoadingDocumentAgreement2File,
  documentAgreement2Files,
  setDocumentAgreement2FileId,
  setDocumentAgreement2Files,
  documentAgreement2Error,
  setDocumentAgreement2Error,
}: Props) => {
  return (
    <Wizard.Page>
      <TitlePage name="Загрузите необходимые документы" number="9" />
      <Divider style={{ margin: 0 }} />
      <div className={cn(styles.container, styles.containerUpload)}>
        {isLoadingMount ? (
          <div className={styles.containerLoader}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#3d935e" }} spin />} />
          </div>
        ) : (
          <>
            <FormField
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.LogoFileId]}
              name={NamesOfFieldEnum.LogoFileId}
              tooltip="Наличие логотипа на платежной странице улучшает визуальное восприятие вашими клиентами страницы и увеличивает конверсию в оплату"
              styleContainer={{ marginBottom: 24, minHeight: 150 }}
              renderError={logoError}
            >
              <div>
                <UploadFile
                  id={NamesOfFieldEnum.LogoFileId}
                  uploadedFiles={logoFiles}
                  setUploadedFiles={setLogoFiles}
                  isLoadingProps={isLoadingLogo}
                  setFileid={setLogoFileId}
                  count={COUNT_UPLOAD_LOGO_FILE}
                  setError={setLogoError}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            </FormField>

            <FormField
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.PassportFileId]}
              name={NamesOfFieldEnum.PassportFileId}
              required
              styleContainer={{ minHeight: 190 }}
              renderError={passportError}
            >
              <div>
                <p style={{ fontSize: '12px', color: '#8c8c8c' }}>Необходимо предоставить скан/фото 2-3 страницы паспорта и страницу с действующей пропиской.</p>
                <p style={{ fontSize: '12px', color: '#8c8c8c', marginBottom: '10px' }}>Данные должны быть четко видны (без засветов, закрытых элементов), особенно серия и номер паспорта.</p>
                <UploadFile
                  id={NamesOfFieldEnum.PassportFileId}
                  uploadedFiles={passportFiles}
                  setUploadedFiles={setPassportFiles}
                  isLoadingProps={isLoadingPassport}
                  setFileid={setPassportFileId}
                  count={COUNT_UPLOAD_PASSPORT_FILE}
                  setError={setPassportError}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            </FormField>

            <h3>Договора:</h3>
            <p style={{ fontSize: '12px', color: '#8c8c8c', marginTop: 10, marginBottom: 20 }}>Если вы самозанятый и оказываете услуги совместно с третьим лицом, то необходимо предоставить договор услуг. Чужие услуги и товары к реализации запрещены, но возможно привлечение третьих лиц через договор.</p>

            <FormField
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.DocumentAgreement1FileId]}
              name={NamesOfFieldEnum.DocumentAgreement1FileId}
              styleContainer={{ minHeight: 190 }}
              renderError={documentAgreement1Error}
            >
              <div>
                <UploadFile
                  id={NamesOfFieldEnum.DocumentAgreement1FileId}
                  uploadedFiles={documentAgreement1Files}
                  setUploadedFiles={setDocumentAgreement1Files}
                  isLoadingProps={isLoadingDocumentAgreement1File}
                  setFileid={setDocumentAgreement1FileId}
                  count={COUNT_DOCUMENT_AGREEMENT1_FILE}
                  setError={setDocumentAgreement1Error}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            </FormField>


            <FormField
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.DocumentAgreement2FileId]}
              name={NamesOfFieldEnum.DocumentAgreement2FileId}
              styleContainer={{ minHeight: 190 }}
              renderError={documentAgreement2Error}
            >
              <div>
                <UploadFile
                  id={NamesOfFieldEnum.DocumentAgreement2FileId}
                  uploadedFiles={documentAgreement2Files}
                  setUploadedFiles={setDocumentAgreement2Files}
                  isLoadingProps={isLoadingDocumentAgreement2File}
                  setFileid={setDocumentAgreement2FileId}
                  count={COUNT_DOCUMENT_AGREEMENT2_FILE}
                  setError={setDocumentAgreement2Error}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            </FormField>
          </>
        )}
      </div>
      <RulesFiles />
      <Divider style={{ margin: 0 }} />
      <AcceptPolitics
        orgType={orgType}
        isAcceptPolitics={isAcceptPolitics}
        setIsAcceptPolitics={setIsAcceptPolitics}
        acceptPoliticsError={acceptPoliticsError}
        setAcceptPoliticsError={setAcceptPoliticsError}
      />
    </Wizard.Page>
  );
};
