import React from 'react';
import { Typography, List } from 'antd';

import styles from "./SocialLinkSelectDescription.module.scss";

const { Text } = Typography;

export const SocialLinkSelectDescription = () => {
	return (
		<div className={styles.descriptionContainer}>
			<p className={styles.descriptionTitle}>Требования к ресурсам:</p>

			<List
				itemLayout="vertical"
				dataSource={[
					{
						title: '1. Telegram-канал или бот:',
						items: [
							'Канал должен быть открытым и доступным.',
							'К закрытому каналу должен быть предоставлен доступ. Для этого добавьте наш аккаунт @prodamus_checker в участники канала, чтобы мы проверили ваш ресурс.',
							'Обязательно наличие ФИО, ИНН и контактных данных.',
							'В боте должны быть указаны данные поставщика услуг (ФИО, ИНН, контактные данные) и прикреплена оферта.',
						],
					},
					{
						title: '2. Сайт и иные соцсети:',
						items: [
							'Для сайта обязательно наличие ФИО, ИНН, контактных данных.',
							'В соцсетях должна быть указана ваша фамилия. Если вы работаете под псевдонимом, то необходимо прикрепить ссылку на интернет-ресурс с данными по паспорту либо прикрепить вашу оферту.',
							'Если у вас юридическое лицо, то на ресурсе должна быть информация про наименование юридического лица и его ИНН.',
							'Если вы — физическое лицо, то ресурс должен содержать данные поставщика услуг по паспорту.',
						],
					},
				]}
				renderItem={(item) => (
					<List.Item style={{ paddingBottom: 0 }}>
						<Text strong className={styles.itemTitle}>{item.title}</Text>
						<List
							dataSource={item.items}
							renderItem={(subItem) =>
								<List.Item className={styles.item}>
									<span className={styles.itemText}>• {subItem}</span>
								</List.Item>}
							size="small"
							bordered={false}
							style={{ paddingLeft: '20px' }}
						/>
					</List.Item>
				)}
			/>
		</div>
	);
};
