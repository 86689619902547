import { TitleField } from "~/components";

import styles from "./RulesFiles.module.scss";

interface RulesFiles {
  isDocuments?: boolean;
  isPartnerTypeQuestionnaires?: boolean;
}

export const RulesFiles = ({ isDocuments = true, isPartnerTypeQuestionnaires = false }: RulesFiles) => {
  return (
    <div className={styles.rulesFiles}>
      <TitleField name="Требования к файлам" styleContainer={{ marginTop: 0 }} />
      {
        isPartnerTypeQuestionnaires ? (
          <ul className={styles.listRulesFiles}>
            {isDocuments && (
              <>
                <li>Скан должен быть сделан с оригинала</li>
                <li>
                  Изображение должно быть цветное и чёткое, никакие элементы не должны быть обрезаны
                </li>
              </>
            )}
            <li>Размер файла не должен превышать 10 Мб</li>
            <li>Файл должен иметь формат PDF, JPG или PNG</li>
          </ul>
        ) : (
          <ul className={styles.listRulesFiles}>
            {isDocuments && (
              <>
                <li>Скан должен быть сделан с оригинала</li>
                <li>
                  Изображение должно быть цветное и чёткое, никакие элементы не должны быть обрезаны
                </li>
              </>
            )}
            <li>Размер файла не должен превышать 10 Мб</li>
            <li>Файл должен иметь формат PDF, JPG или PNG (для паспорта)</li>
            <li>Файл должен иметь формат DOC, DOCX, ODT или PDF (для договора или оферты)</li>
          </ul>
        )
      }
    </div>
  );
};
