export type TypeQuestionnaires = "partner" | "customer" | "customerV2";

export function getTypeQuestionnaires(): TypeQuestionnaires {
  const path = window.location.pathname;
  const segments = path.split("/").filter(Boolean);
  const route = segments[0];
  
  if (route === "customer" || route === "customerV2" || route === "partner") {
    return route;
  }

  return "customer";
}
