import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactNode } from "react";

const loadingIcon = <LoadingOutlined style={{ fontSize: 18, color: "#3d935e" }} spin />;

interface LoadingContainerProps {
	isLoading: boolean;
	children: ReactNode;
}

export const LoadingContainer: React.FC<LoadingContainerProps> = ({ isLoading, children }) => {
	return isLoading ? <Spin indicator={loadingIcon} /> : <>{children}</>;
};