import { NameStepsEnum, NameStepsEnumCustomerV2, NameStepsEnumPartner } from "~/typings/types";
import { getTypeQuestionnaires } from "~/utils";
import { TypeQuestionnaires } from "~/utils/getTypeQuestionnaires";
import { routes } from "./routes";

const stepsConfigMap: Record<string, Array<string>> = {
  [routes.customer.slice(1)]: [
    "default",
    NameStepsEnum.AccountDetails,
    NameStepsEnum.CompanyDetails,
    NameStepsEnum.ContactDetails,
    NameStepsEnum.PayformDetails,
    NameStepsEnum.PayformTechDetails,
    NameStepsEnum.MarketplaceDetails,
    NameStepsEnum.AdditionalDetails,
    NameStepsEnum.AttachmentsDetails,
  ],
  [routes.partner.slice(1)]: [
    "default",
    NameStepsEnumPartner.AccountDetails,
    NameStepsEnumPartner.CompanyDetails,
    NameStepsEnumPartner.PayformDetails,
    NameStepsEnumPartner.AttachmentsDetails,
  ],
  [routes.v2customer.slice(1)]: [
    "default",
    NameStepsEnumCustomerV2.AccountDetails,
    NameStepsEnumCustomerV2.CompanyDetails,
    NameStepsEnumCustomerV2.PayformTechDetails,
    NameStepsEnumCustomerV2.AdditionalDetails,
    NameStepsEnumCustomerV2.AttachmentsDetails,
  ],
};

export const getConfigSteps = (): Array<string> => {
  const type = getTypeQuestionnaires();
  return stepsConfigMap[type];
};

export const configSteps = getConfigSteps();