// @ts-nocheck
import { Field } from "react-final-form";
import { TitlePage, PhoneField } from "~/components";
import { composeValidators, disabledValidate, required, testRegExp } from "~/utils/validate";
import { Divider } from "antd";
import { Wizard, FormField, SocialLinkSelectField } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfIndividualEntrepreneur } from "../configTitleFields";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

export const PayformDetails = () => {
  return (
    <Wizard.Page>
      <TitlePage
        name="Укажите контактные данные вашей организации, которые будут размещены на платёжной странице"
        number="5"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.PayformPhone]}
          name={NamesOfFieldEnum.PayformPhone}
          required
          tooltip="Обязательное требование платёжных систем"
          orderNumber="1"
          triggerAutofill={[NamesOfFieldEnum.PayformPhone, NamesOfFieldEnum.CrmPhone]}
        >
          <Field
            name={NamesOfFieldEnum.PayformPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            validate={disabledValidate(
              composeValidators(
                required(),
                testRegExp(/^[+7]/, "Номер телефона должен начинаться на +7")
              )
            )}
            component={props => <PhoneField orderNumber="1" onlyCountries={["ru"]} {...props} />}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.PayformEmail]}
          name={NamesOfFieldEnum.PayformEmail}
          tooltip="Если не хотите указывать свой email на платёжной странице, оставьте поле пустым"
          orderNumber="2"
          triggerAutofill={[NamesOfFieldEnum.PayformEmail, NamesOfFieldEnum.CrmEmail]}
        />

        <SocialLinkSelectField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.PayformLinks]}
          name={NamesOfFieldEnum.PayformLinks}
          orderNumber="3"
          fieldsLinks={[
            [NamesOfFieldEnum.PayformLinkVk],
            [NamesOfFieldEnum.PayformLinkTg],
            [NamesOfFieldEnum.PayformLinkInstagram],
            [NamesOfFieldEnum.PayformLinkOther],
            [NamesOfFieldEnum.PayformLinkSite],
          ]}
          descriptionContent={
            <div>
              <p style={{ marginBottom: "10px" }}>Требования к ресурсам:</p>
              <p style={{ marginBottom: "10px" }}>Интернет-ресурс (сайт, сообщество/страница/бот) обязательно должен быть открытым и доступным. На нём должно присутствовать описание услуг, за которые вы планируете принимать оплату через наш сервис. </p>
              <ol>
                <li>Если это закрытый Telegram-канал, то должен быть предоставлен к нему постоянный доступ.</li>
                <li>
                  Если вы — физическое лицо, то ресурс должен содержать данные поставщика услуг по паспорту.
                  <ol type="a" style={{ marginLeft: "12px" }}>
                    <li>Если указываете сайт или Telegram-канал, то обязательно наличие ФИО, ИНН, контактных данных.</li>
                    <li>Если вы указываете соцсеть, как минимум там должна быть указана ваша фамилия.</li>
                    <li>Если вы работаете под псевдонимом, то необходимо прикрепить ссылку на интернет-ресурс с данными по паспорту либо прикрепить вашу оферту.</li>
                  </ol>
                </li>
                <li>Если у вас юридическое лицо, то на ресурсе должна присутствовать информация про наименование юридического лица и его ИНН.</li>
                <li>
                  Если указывается Telegram-бот, то на нем должны быть указаны данные поставщика услуг (ФИО, ИНН, контактные данные) и прикреплена оферта.
                </li>
              </ol>
            </div>
          }
        />
      </div>
    </Wizard.Page>
  );
};
