import cn from "classnames";
import { CustomButton } from "~/components";
import { ModalToggle } from "~/hooks/useModal";
import { formatDate } from "../utils/formatDate";

import styles from "./ModalContent.module.scss";

type Props = {
  errorManyAttempts: string;
  closeModal: ModalToggle;
};

export const ModalContentOverAttempt = ({ errorManyAttempts, closeModal }: Props) => {
  const handleSubmit = async () => {
    try {
      closeModal();
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>Ошибка</p>
        <button
          type="button"
          className={styles.buttonClose}
          onClick={closeModal}
        />
      </div>
      <div className={styles.content}>
        <p>Слишком много попыток.</p>
        <p>Вы можете:</p>
        <ul>
          <li>Изменить номер телефона</li>
          <li>
            Попытаться снова запросить код после {formatDate(errorManyAttempts)}
          </li>
          <li>Отправить анкету без подтверждения номера телефона</li>
        </ul>
      </div>
      <div className={styles.footer}>
        <CustomButton
          name="Продолжить"
          type="button"
          className={cn(styles.buttonSubmit)}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
