
import { Field, FormSpy } from "react-final-form";
import { RadioField, TitlePage } from "~/components";
import { composeValidators, disabledValidate, required } from "~/utils/validate";
import { Divider, Select } from "antd";
import { Wizard, FormField } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfIndividualEntrepreneur, optionsSelectClientFrom } from "../configTitleFields";
import { FullWidthRowFormContainer } from "~/pages/questionnaires/shared/form-field";
import { V2Wizard } from "../../../../shared/wizard";

import styles from "./CommonSteps.module.scss";

const { Option } = Select;

interface AdditionalDetailsProps {
  queryPromocode?: string
}

export const AdditionalDetails = ({ queryPromocode }: AdditionalDetailsProps) => {
  return (
    <V2Wizard.Page>
      <TitlePage name="Дополнительные сведения" number="5" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.ClientFrom]}
            name={NamesOfFieldEnum.ClientFrom}
            required
            validate={composeValidators(required())}
            orderNumber="1"
          >
            <Field
              name={NamesOfFieldEnum.ClientFrom}
              subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
              validate={disabledValidate(composeValidators(required()))}
              component={({ input, meta }) => (
                <Select
                  {...input}
                  placeholder="Выберите вариант"
                  onChange={(value) => input.onChange(value)}
                  onBlur={(e) => input.onBlur(e)}
                  allowClear
                >
                  {optionsSelectClientFrom.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </FormField>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormSpy subscription={{ values: true }}>
            {propsSpy => (
              <>
                {propsSpy.values[NamesOfFieldEnum.ClientFrom] === "other" && (
                  <FormField
                    titleField={""}
                    name={NamesOfFieldEnum.ClientFromOther}
                    isColon={false}
                    required
                    validate={composeValidators(required())}
                  />
                )}
              </>
            )}
          </FormSpy>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={
              configTitleFieldsOfIndividualEntrepreneur[
              NamesOfFieldEnum.PaymentMethodProdamusSubscription
              ]
            }
            name={NamesOfFieldEnum.PaymentMethodProdamusSubscription}
            required
            tooltip="Для безбумажного подписания необходимо провести оплату с р/с ИП или ЮЛ, с которым заключается договор. Для ускорения подключения направить платежное поручение, т.к. оплата по счету может поступить в течение трех дней."
          >
            <div>
              <Field
                name={NamesOfFieldEnum.PaymentMethodProdamusSubscription}
                type="radio"
                value="settlement_account"
                component={props => <RadioField orderNumber="2" {...props} />}
                validate={disabledValidate(composeValidators(required()))}
              >
                Оплачу с расчетного счета ИП или ЮЛ без ручного подписания заявления
              </Field>
              <Field
                name={NamesOfFieldEnum.PaymentMethodProdamusSubscription}
                type="radio"
                value="other"
                component={props => <RadioField orderNumber="2" {...props} />}
                validate={disabledValidate(composeValidators(required()))}
              >
                Оплачу любым способом (карта, СБП) с подписанием заявления
              </Field>
            </div>
          </FormField>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.ClientPromocode]}
            name={NamesOfFieldEnum.ClientPromocode}
            disabled={!!queryPromocode}
            orderNumber="3"
          />
        </FullWidthRowFormContainer>

      </div>
    </V2Wizard.Page>
  );
};
