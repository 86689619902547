// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { setIn } from "final-form";
import cn from "classnames";
import { SuccessPage, ErrorPage } from "~/pages";
import { CustomButton, DevActions } from "~/components";
import { configSteps } from "~/configs/steps";
import { NamesOfFieldEnum, StatusPage } from "~/typings/types";
import {
  ERROR_FIELD_,
  KEY_CUSTOMER_CLIENT_ID_V2,
  KEY_PARTNER_CLIENT_ID,
  BUTTON_BACK_ID,
} from "~/configs/consts";
import { apiServicePartner } from "~/pages/questionnaires/v1/partner/api/apiService";
import { captureException } from "@sentry/browser";
import { sendYandexMetrika } from "~/utils/sendYandexMetrika";
import { clientApi, getCountOfStepsInAnketa, getKeyClientId, getTypeQuestionnaires } from "~/utils";
import { REPORT_ERROR } from "../../api/apiUrls";

import styles from "./V2Wizard.module.scss";
import { apiServiceCustomer } from "../../api/apiService";

export class V2Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setIsSubmit: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    orgType: PropTypes.string.isRequired,
    setIsReturnedFirstStep: PropTypes.func.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
    clientId: PropTypes.string,
    logoFileId: PropTypes.arrayOf(PropTypes.number).isRequired,
    passportFileId: PropTypes.arrayOf(PropTypes.number),
    documentAgreement1FileId: PropTypes.arrayOf(PropTypes.number).isRequired,
    documentAgreement2FileId: PropTypes.arrayOf(PropTypes.number).isRequired,
    handleRequestAttachmentsDetails: PropTypes.func.isRequired,
    isLoadingAttachmentsDetails: PropTypes.bool.isRequired,
    isErrorAttachmentsDetails: PropTypes.bool.isRequired,
    COUNT_UPLOAD_MAX_REQUIRED_FILE: PropTypes.number,
    isRenderSuccessPage: PropTypes.bool.isRequired,
    setIsRenderSuccessPage: PropTypes.func.isRequired,
    isRenderErrorPage: PropTypes.bool.isRequired,
    setIsRenderErrorPage: PropTypes.func.isRequired,
    isAcceptPolitics: PropTypes.bool.isRequired,
    setAcceptPoliticsError: PropTypes.func.isRequired
  };

  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: props.currentStep || 1,
      values: props.initialValues || {},
      clientId: props.clientId,
      orgType: props.orgType,
      isLoading: false
    };
  }

  componentDidMount() {
    // Получаем clientId из локального хранилища при монтировании компонента
    const itemClientId = localStorage.getItem(getKeyClientId());
    const storedClientId = itemClientId ? JSON.parse(itemClientId) : null;

    if (storedClientId) {
      this.setState({ clientId: storedClientId });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Проверяем, изменился ли clientId в локальном хранилище
    const itemClientId = localStorage.getItem(getKeyClientId());
    const storedClientId = itemClientId ? JSON.parse(itemClientId) : null;

    if (storedClientId !== this.state.clientId) {
      this.setState({ clientId: storedClientId });
    }
  }

  next = values => {
    this.setState(state => ({
      page: state.page + 1,
      values
    }));
  };

  previous = (_, form, values) => {
    const { setIsSubmit, setIsReturnedFirstStep, setCurrentStep } = this.props;
    const { page } = this.state;
    if (page === 1) {
      setIsSubmit(false);
      setIsReturnedFirstStep(true);
      setCurrentStep(1);
      return;
    }

    this.setState(state => ({
      page: state.page - 1
    }));
  };

  toggleIsLoading = () => {
    this.setState(state => ({
      ...state,
      isLoading: !state.isLoading
    }));
  };

  setSuccessReq = () => {
    this.props.setIsRenderSuccessPage(true);
  };

  setErrorPage = (error: any, values: any) => {
    const { page, clientId } = this.state;

    captureException(error);

    clientApi.post(REPORT_ERROR, {
      data: {
        id: clientId,
        chapter_name: configSteps[page],
        other: {
          user_agent: navigator.userAgent,
          fields: values,
          ...(error && Object.keys(error).length && { traceError: error })
        }
      }
    });

    this.props.setIsRenderErrorPage(true);
  };

  handleSubmitError = ({ values, responseErrors }) => {
    let errors = {};
    const setError = (key, value) => {
      errors = setIn(errors, key, value);
    };

    responseErrors.forEach(err => {
      const fieldName = err.data.target;
      setError(fieldName, err.message);
    });

    if (Object.entries(errors).length > 0) {
      return errors;
    }
  };

  handleRequestLastPage = async ({ clientId, values }) => {
    const { onSubmit } = this.props;

    await this.toggleIsLoading();

    // TODO: переписать на async
    onSubmit({
      clientId
    })
      .then(() => {
        this.setSuccessReq();
        getTypeQuestionnaires() === "partner"
          ? localStorage.removeItem(KEY_PARTNER_CLIENT_ID)
          : localStorage.removeItem(KEY_CUSTOMER_CLIENT_ID_V2);
        sendYandexMetrika(94089842, "reachGoal", `anketa-success`);
      })
      .catch(err => {
        this.setErrorPage(err, values);
        sendYandexMetrika(94089842, "reachGoal", `anketa-fail`);
      })
      .finally(() => {
        this.toggleIsLoading();
      });
  };

  handleSubmit = async values => {
    try {
      const {
        children,
        handleRequestAttachmentsDetails,
        orgType,
        setAcceptPoliticsError,
        isAcceptPolitics,
        typeRequestCheckPayformName,
        logoFileId
      } = this.props;
      const { page, clientId } = this.state;
      const isLastPage = page === React.Children.count(children) - 0;
      const isAttachmentsPage = page === React.Children.count(children) - 1;

      if (isAttachmentsPage) {
        if (!isAcceptPolitics) {
          return setAcceptPoliticsError({ has: 1, message: "Для продолжения необходимо принять" });
        }
        setAcceptPoliticsError(null);

        try {
          const statusRequest = await handleRequestAttachmentsDetails({ orgType });
          if (statusRequest === 0) {
            return;
          }

          sendYandexMetrika(94089842, "reachGoal", `step${page}`);

          await this.handleRequestLastPage({ clientId, values });
          return;
        } catch (error) {
          throw error;
        }
      }
      if (
        typeRequestCheckPayformName === "loading" ||
        typeRequestCheckPayformName === "error" ||
        typeRequestCheckPayformName === "errorValidateFromApi"
      ) {
        const errorInputs = document.querySelectorAll(`[id^=${ERROR_FIELD_}]`);
        const inputFirstError = errorInputs?.length && errorInputs[0];

        if (inputFirstError) {
          inputFirstError?.scrollIntoView({ behavior: "smooth", block: "center" });
          setTimeout(() => {
            (inputFirstError as HTMLElement)?.focus();
          }, 1000);
        }
        return;
      }
      await this.toggleIsLoading();
      if (isLastPage) {
        return this.handleRequestLastPage({ clientId, values });
      }
      const apiService =
        getTypeQuestionnaires() === "partner" ? apiServicePartner : apiServiceCustomer;
  
      const responseApi = await apiService({
        values,
        pageId: configSteps[page],
        clientId,
        orgType: this.state.orgType,
        logoFileId
      });
      
      await this.toggleIsLoading();

      if (Array.isArray(responseApi?.errors) && responseApi?.errors.length > 0) {
        return this.handleSubmitError({ values, responseErrors: responseApi.errors });
      }

      sendYandexMetrika(94089842, "reachGoal", `step${page}`);

      this.next(values);
    } catch (err) {
      this.setErrorPage(err, values);
    }
  };

  renderResultPage() {
    const { isRenderSuccessPage, isRenderErrorPage } = this.props;

    const isPartner = getTypeQuestionnaires() === "partner";

    if (isRenderErrorPage) {
      return <ErrorPage />;
    }

    if (isRenderSuccessPage) {
      return <SuccessPage isPartner={isPartner} />;
    }
  }

  getCountOfSteps = values => {
    return getCountOfStepsInAnketa();
  };

  getCurrentPage = (page, values) => {
    return page
  };

  renderNextButton = () => {
    const {
      isLoadingAttachmentsDetails,
      logoFileId,
      passportFileId,
      documentAgreement1FileId,
      documentAgreement2FileId,
      handleGetRequestCode,
      isLoadingGetRequestCode,
      isErrorAttachmentsDetails,
      acceptPoliticsError,
      children
    } = this.props;
    const { isLoading, page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;

    const isDisabledNextButton =
      // (passportFileId && !passportFileId.length) ||
      isLoadingAttachmentsDetails ||
      isLoading ||
      (isErrorAttachmentsDetails && isLastPage) ||
      (this.props.acceptPoliticsError?.has && isLastPage);

    return (
      <CustomButton
        type="submit"
        name={'Продолжить'}
        className={cn(styles.button, styles.buttonNextAndSubmit, {
          [styles.buttonDisabled]: isDisabledNextButton
        })}
        disabled={isDisabledNextButton}
        isLoading={isLoading || isLoadingAttachmentsDetails}
      />
    );
  };

  render() {
    const { children, isRenderSuccessPage, isRenderErrorPage } = this.props;
    const { page, values, isLoading } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    const countAllSteps = this.getCountOfSteps(values);
    const currentPage = this.getCurrentPage(page, values);

    if (isRenderSuccessPage || isRenderErrorPage) {
      return this.renderResultPage();
    }

    return (
      <Form initialValues={values} onSubmit={this.handleSubmit}>
        {({ handleSubmit, form, values }) => {
          return (
            <div className={styles.container}>
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={cn({ [styles.main]: !(isLastPage && isRenderSuccessPage) })}>
                  {activePage}
                </div>

                {!isRenderSuccessPage && !isRenderErrorPage && (
                  <div className={styles.footer}>
                    <div className={styles.footerHead}>
                      <p className={styles.steps}>{`Шаг ${currentPage + 1} из ${countAllSteps}`}</p>
                      <div className={styles.containerButtons}>
                        <DevActions>
                          <button onClick={this.props.onResetData}>Отчистить всю форму</button>
                        </DevActions>
                        {((page >= 0 && page < 9) ||
                          (page === 9)) && (
                            <CustomButton
                              type="button"
                              onClick={event => this.previous(event, form, values)}
                              className={cn(styles.button, styles.buttonBack)}
                              name="Назад"
                              disabled={isLoading}
                              id={BUTTON_BACK_ID}
                            />
                          )}
                        {this.renderNextButton()}
                      </div>
                    </div>
                  </div>
                )}
                {/* <pre style={{ position: 'absolute', bottom: 0 }}>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            </div>
          );
        }}
      </Form>
    );
  }
}
