import React, { useState, type FC } from "react";

import { ClientId } from "~/typings/types";
import { AutofillFieldButton, ErrorField, TitleField } from "~/components";

import styles from "./Autocomplete.module.scss";
import { AutocompleteInput } from "./AutocompleteInput";
import { S } from "vite/dist/node/types.d-aGj9QkWt";

export type GetErrorSuggestions = {
  code: string;
  message: string;
}| null

interface Props {
  name: string;
  nameFiasId: string;
  clientId: ClientId;
  titleField?: string;
  validate?: any;
  required?: boolean;
  tooltip?: string;
  isColon?: boolean;
  styleContainerTitleField?: any;
  styleContainer?: any;
  placeholder?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  renderError?: any;
  triggerAutofill?: string[];
  triggerAutofillForAddressFiasId?: string[];
  propsSpy: any;
  urlPersonalDataAddressSuggest: string;
  orderNumber: string;
}

export const FormFieldAutocompleteAddress: FC<Props> = ({
  name,
  nameFiasId,
  clientId,
  titleField,
  validate,
  required,
  tooltip,
  isColon,
  styleContainerTitleField,
  styleContainer,
  renderError,
  triggerAutofill,
  triggerAutofillForAddressFiasId,
  propsSpy,
  disabled,
  urlPersonalDataAddressSuggest,
  orderNumber,
}) => {
  const [errorGetSuggestions, setErrorGetSuggestions] = useState<GetErrorSuggestions>(null);
  const customErrorField =
    renderError || (errorGetSuggestions && { has: 1, message: errorGetSuggestions.message });

  const errors = propsSpy.errors;
  const nameErrorField =
    errors && errors?.[name] === "Обязательно для заполнения" ? name : nameFiasId;

  return (
    <div className={styles.containerField} style={styleContainer}>
      {titleField && (
        <TitleField
          name={titleField}
          required={required}
          tooltip={tooltip}
          isColon={isColon}
          styleContainer={styleContainerTitleField}
        />
      )}

      <div className={styles.containerInput}>
        <AutocompleteInput
          name={name}
          nameFiasId={nameFiasId}
          clientId={clientId}
          propsSpy={propsSpy}
          validate={validate}
          disabled={disabled}
          setErrorGetSuggestions={setErrorGetSuggestions}
          urlPersonalDataAddressSuggest={urlPersonalDataAddressSuggest}
          customError={!!customErrorField}
          orderNumber={orderNumber}
        />
      </div>

      <AutofillFieldButton
        triggerAutofill={triggerAutofill}
        triggerAutofillForAddressFiasId={triggerAutofillForAddressFiasId}
      />
      <ErrorField name={nameErrorField} renderError={customErrorField} />
    </div>
  );
};