import { useState, useEffect, useRef, Dispatch } from "react";
import { useDebounce } from "usehooks-ts";
import { Field, FormSpyRenderProps } from "react-final-form";
import { Divider, Input, List, Spin } from "antd";
import styles from "./Autocomplete.module.scss";
import { disabledValidate } from "~/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { useSuggestions } from "./hooks/useSuggestions";
import cn from "classnames";
import { TypeSuggestionData } from "~/typings/types";
import { GetErrorSuggestions } from "./FormFieldAutocomplete";
import { ERROR_FIELD_ } from "~/configs/consts";

const antIcon = <LoadingOutlined className={styles.spin} spin />;

export interface AutocompleteInputProps<T extends TypeSuggestionData> {
  name: string;
  clientId: string;
  propsSpy: FormSpyRenderProps<Record<string, any>, Partial<Record<string, any>>>;
  validate?: any;
  disabled?: boolean;
  setErrorGetSuggestions: Dispatch<GetErrorSuggestions>;
  urlPersonalDataAddressSuggest: string;
  customError?: boolean;
  keyValueAutocompleteSelected?: keyof T;
  handleInputChangeFromProps?: () => void;
  handleSuggestionClickFromProps?: (suggestion: T) => void;
  orderNumber: string;
}

export const AutocompleteInput = <T extends TypeSuggestionData>({
  name,
  clientId,
  validate,
  disabled = false,
  setErrorGetSuggestions,
  urlPersonalDataAddressSuggest,
  customError = false,
  keyValueAutocompleteSelected = ("value" as keyof TypeSuggestionData),
  handleInputChangeFromProps,
  handleSuggestionClickFromProps,
  orderNumber
}: AutocompleteInputProps<T>) => {
  const [query, setQuery] = useState<string>("");
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const listItemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const {
    suggestions,
    setSuggestions,
    isPendingResponseSuggestions,
    fetchSuggestions,
  } = useSuggestions<T>(clientId, setErrorGetSuggestions, urlPersonalDataAddressSuggest);

  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    if (debouncedQuery) {
      fetchSuggestions(debouncedQuery);
      setIsMenuOpen(true);
    } else {
      setSuggestions([]);
      setIsMenuOpen(false);
    }
  }, [debouncedQuery]);

  const handleInputChange = (inputValue: string) => {
    if (inputValue.trim() === "") {
      return;
    }

    if (handleInputChangeFromProps) {
      handleInputChangeFromProps();
    }
    setQuery(inputValue);
    setFocusedIndex(-1);
  };

  const handleSuggestionClick = (
    suggestion: T,
    input: {
      onChange: (value: string) => void;
    }
  ) => {
    if (handleSuggestionClickFromProps) {
      handleSuggestionClickFromProps(suggestion);
    }

    if (keyValueAutocompleteSelected && keyValueAutocompleteSelected in suggestion) {
      input.onChange(suggestion[keyValueAutocompleteSelected] as string);
    }

    setSuggestions([]);
    setIsMenuOpen(false);
    setFocusedIndex(-1);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    input: {
      onChange: (value: string) => void;
    }
  ) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedIndex((prevIndex) => Math.min(prevIndex + 1, suggestions.length - 1));
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (
      event.key === "Enter" &&
      focusedIndex >= 0 &&
      suggestions.length > 0
    ) {
      event.preventDefault();
      handleSuggestionClick(suggestions[focusedIndex], input);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target as Node)
    ) {
      // setIsMenuOpen(false); // Закрываем меню только при клике вне компонента
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Field
      name={name}
      validate={disabledValidate(validate)}
      subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
    >
      {({ input, meta: { submitFailed, error, submitError } }) => {
        const hasError =
          customError || (error || submitError) && submitFailed;

        const inputId = hasError ? `${ERROR_FIELD_ + orderNumber}` : undefined;

        return (
          <div style={{ width: "100%", margin: "0 auto", position: "relative" }}>
            <div className={styles.containerInput}>
              <Input
                disabled={disabled}
                {...input}
                id={inputId}
                className={cn(styles.inputCustom, {
                  [styles.inputCustomError]: hasError,
                })}
                onChange={(event) => {
                  input.onChange(event);
                  handleInputChange(event.target.value);
                }}
                onKeyDown={(event) => handleKeyDown(event, input)}
              />
              {isPendingResponseSuggestions && (
                <Spin indicator={antIcon} className={styles.containerSpin} />
              )}
            </div>
            {isMenuOpen && suggestions.length > 0 && (
              <div
                ref={suggestionsRef}
                style={{
                  marginTop: "4px",
                  width: "100%",
                  maxHeight: "150px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <List
                  dataSource={suggestions}
                  renderItem={(suggestion, index) => (
                    <List.Item
                      key={index}
                      ref={(el) => (listItemRefs.current[index] = el)}
                      onClick={() => handleSuggestionClick(suggestion, input)}
                      className={
                        focusedIndex === index
                          ? styles.focusedListItem
                          : styles.listItem
                      }
                      onMouseEnter={() => setFocusedIndex(index)}
                    >
                      {keyValueAutocompleteSelected && suggestion[keyValueAutocompleteSelected]
                        ? `${suggestion[keyValueAutocompleteSelected] as string}`
                        : null}
                    </List.Item>
                  )}
                />
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

