import { clientApi } from "~/utils/clientApi";
import { DefaultAxiosRequestConfig } from "~/api/apiMethods";
import {
  DRAFT_PREVIEW,
  DRAFT_ACCOUNT_DETAILS_V2,
  DRAFT_COMPANY_DETAILS_V2,
  DRAFT_PAYFORM_TECH_DETAILS_V2,
  DRAFT_ADDITIONAL_DETAILS_V2,
  DRAFT_ATTACHMENTS_DETAILS_V2,
} from "./apiUrls";
import {
  ClientId,
  AccountDetailsFormFieldsCustomerV2,
  CompanyDetailsFormFieldsCustomerV2,
  PayformTechDetailsFormFieldsCustomerV2,
  AdditionalDetailsFormFieldsCustomerV2,
  AttachmentsDetailsFormFieldsCustomerV2,
  NameStepsEnumCustomerV2
} from "~/typings/types";

export type ResSuccess = 1 | 0;

export interface ReqPostData<T> {
  id: ClientId;
  is_presave_mode?: boolean;
  fields: T;
}

export interface ReqPostArguments<T> {
  data: ReqPostData<T>;
  config?: DefaultAxiosRequestConfig;
}

export interface ErrorFieldFromBackend {
  code: "field_validation" | "confirmation:incorrect_code" | "code_confirmation:too_many_attempts";
  message: string;
  data: {
    target_type: string;
    target: string;
  };
}

export interface ResPost {
  success: ResSuccess;
  errors?: ErrorFieldFromBackend[];
}

export interface ApiResponseGetClientIdByHash {
  success: ResSuccess;
  data: {
    id: ClientId;
  };
}

interface DraftPrewievId {
  clientId: string;
}

export interface ApiResponseDraftPreviewId {
  success: 1 | 0;
  data: {
    questionnaire_id: ClientId;
    chapters: Partial<{
      [NameStepsEnumCustomerV2.AccountDetails]: AccountDetailsFormFieldsCustomerV2;
      [NameStepsEnumCustomerV2.AdditionalDetails]: AdditionalDetailsFormFieldsCustomerV2;
      [NameStepsEnumCustomerV2.CompanyDetails]: CompanyDetailsFormFieldsCustomerV2;
      [NameStepsEnumCustomerV2.PayformTechDetails]: PayformTechDetailsFormFieldsCustomerV2;
    }>;
    flat: FormData;
    ready_to_save: 1 | 0;
    errors: [];
    chapter_latest_int: number;
    chapter_latest: NameStepsEnumCustomerV2;
    shortcut_hash: string;
    should_generate_new_id?: boolean;
  };
}

export const getDraftPreviewId = async ({
  clientId
}: DraftPrewievId): Promise<ApiResponseDraftPreviewId> => {
  try {
    const res = await clientApi.get(`${DRAFT_PREVIEW}/${clientId}`);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getDraftPreviewId");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

type DraftEndpoints =
  | typeof DRAFT_ACCOUNT_DETAILS_V2
  | typeof DRAFT_COMPANY_DETAILS_V2
  | typeof DRAFT_PAYFORM_TECH_DETAILS_V2
  | typeof DRAFT_ADDITIONAL_DETAILS_V2
  | typeof DRAFT_ATTACHMENTS_DETAILS_V2

type DraftFormFields =
  | AccountDetailsFormFieldsCustomerV2
  | AdditionalDetailsFormFieldsCustomerV2
  | CompanyDetailsFormFieldsCustomerV2
  | PayformTechDetailsFormFieldsCustomerV2
  | AttachmentsDetailsFormFieldsCustomerV2

const postDraftDetails = async <T extends DraftFormFields>(
  endpoint: DraftEndpoints,
  { data, config }: ReqPostArguments<T>
): Promise<ResPost> => {
  try {
    const res = await clientApi.post(endpoint, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftCompanyDetails = (args: ReqPostArguments<CompanyDetailsFormFieldsCustomerV2>) =>
  postDraftDetails(DRAFT_COMPANY_DETAILS_V2, args);

export const postDraftAccountDetails = (args: ReqPostArguments<AccountDetailsFormFieldsCustomerV2>) =>
  postDraftDetails(DRAFT_ACCOUNT_DETAILS_V2, args);

export const postDraftPayformTechDetails = (args: ReqPostArguments<PayformTechDetailsFormFieldsCustomerV2>) =>
  postDraftDetails(DRAFT_PAYFORM_TECH_DETAILS_V2, args);

export const postDraftAttachmentsDetails = (args: ReqPostArguments<AttachmentsDetailsFormFieldsCustomerV2>) =>
  postDraftDetails(DRAFT_ATTACHMENTS_DETAILS_V2, args);

export const postDraftAdditionalDetails = (args: ReqPostArguments<AdditionalDetailsFormFieldsCustomerV2>) =>
  postDraftDetails(DRAFT_ADDITIONAL_DETAILS_V2, args);
