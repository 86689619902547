import { KEY_CUSTOMER_CLIENT_ID_V2, KEY_PARTNER_CLIENT_ID } from "~/configs/consts";
import { getTypeQuestionnaires, TypeQuestionnaires } from "./getTypeQuestionnaires";

export const getKeyClientId = () => {
  const typeQuestionnaires: TypeQuestionnaires = getTypeQuestionnaires();

  const KEY_CLIENT_ID =
    typeQuestionnaires === "partner" ? KEY_PARTNER_CLIENT_ID : KEY_CUSTOMER_CLIENT_ID_V2;

  return KEY_CLIENT_ID;
};
