import { type Dispatch, type MouseEventHandler, useRef } from "react";

import cn from "classnames";
import { Divider } from "antd";

import { useHover } from "~/hooks/useHover";
import { OrgTypeEnum } from "~/typings/types";
import { mockDataForm } from "~/pages/questionnaires/shared";
import { CustomButton, DevActions, Icon, TitlePage } from "~/components";

import styles from "./FirstStep.module.scss";
import { getCountOfStepsInAnketa } from "~/utils";

interface Props {
  onResetData: MouseEventHandler<HTMLButtonElement>;
  setFormData: Dispatch<object>;
  setIsSubmit: Dispatch<boolean>;
  setOrgType: Dispatch<OrgTypeEnum>;
  orgType: OrgTypeEnum;
}

export const FirstStep = ({
  orgType,
  setOrgType,
  setIsSubmit,
  setFormData,
  onResetData
}: Props): JSX.Element => {
  const selfEmployedRef = useRef(null);
  const isHoveredSelfEmployed = useHover(selfEmployedRef);
  const individualEntrepreneurRef = useRef(null);
  const isHoveredIndividualEntrepreneur = useHover(individualEntrepreneurRef);
  const companyRef = useRef(null);
  const isHoveredCompany = useHover(companyRef);
  const countAllSteps = getCountOfStepsInAnketa();

  const configOrgType = [
    {
      value: OrgTypeEnum.SelfEmployed,
      id: OrgTypeEnum.SelfEmployed,
      checked: orgType === OrgTypeEnum.SelfEmployed,
      ref: selfEmployedRef,
      className: cn(styles.SelfEmployed),
      srcCondition:
        (orgType === OrgTypeEnum.SelfEmployed && !isHoveredSelfEmployed) || isHoveredSelfEmployed,
      label: "Самозанятый",
      IconComponent: <Icon name={"self-employed"} className={styles.iconType} />
    },
    {
      value: OrgTypeEnum.IndividualEntrepreneur,
      id: OrgTypeEnum.IndividualEntrepreneur,
      checked: orgType === OrgTypeEnum.IndividualEntrepreneur,
      ref: individualEntrepreneurRef,
      className: cn(styles.IndividualEntrepreneur),
      srcCondition:
        (orgType === OrgTypeEnum.IndividualEntrepreneur && !isHoveredIndividualEntrepreneur) ||
        isHoveredIndividualEntrepreneur,
      label: "ИП",
      IconComponent: <Icon name={"individual-entrepreneur"} className={styles.iconType} />
    },
    {
      value: OrgTypeEnum.Company,
      id: OrgTypeEnum.Company,
      checked: orgType === OrgTypeEnum.Company,
      ref: companyRef,
      className: cn(styles.Company),
      srcCondition: (orgType === OrgTypeEnum.Company && !isHoveredCompany) || isHoveredCompany,
      label: "Юрлицо",
      IconComponent: <Icon name={"company"} className={styles.iconType} />
    }
  ];

  const onHandleSubmit = () => {
    setIsSubmit(true);
  };

  const onHandleClickOrgType = (orgType: OrgTypeEnum) => {
    setOrgType(orgType);
  };

  const onHandleMockData = () => {
    setIsSubmit(true);
    setFormData(mockDataForm);
  };

  return (
    <div className={styles.container}>
      <div className={cn(styles.form, styles.formHeader)}>
        <h2 className={styles.title}>Заявка на подключение к Prodamus</h2>
        <p className={styles.titleDesc}>Заполнение анкеты займет не более 15 минут</p>
      </div>
      <div className={cn(styles.form, styles.formMain)}>
        <div className={styles.containerField}>
          <TitlePage name="Выберите правовую форму" number="1"/>

          <Divider style={{ margin: 0 }} />

          <div className={cn(styles.containerButtonsOrgType)}>
            {configOrgType.map(
              ({ value, id, checked, ref, className, srcCondition, label, IconComponent }) => {
                return (
                  <button
                    key={id}
                    ref={ref}
                    className={cn(styles.buttonOrgType, className, {
                      [styles.active]: checked
                    })}
                    onClick={() => onHandleClickOrgType(value)}
                  >
                    {IconComponent}

                    {srcCondition ? (
                      <Icon name="dot-active" className={styles.dotActive} />
                    ) : (
                      <div className={styles.dotDefault} />
                    )}
                    <span className={styles.buttonLabelOrgType}>{label}</span>
                  </button>
                );
              }
            )}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.containerButtonsActions}>
          <p className={styles.steps}>{`Шаг 1 из ${countAllSteps}`}</p>
          <DevActions>
            <button onClick={onResetData}>Отчистить всю форму</button>
            <button onClick={onHandleMockData}>Заполнить тестовыми данными</button>
          </DevActions>
          <CustomButton
            name="Продолжить"
            onClick={() => {
              onHandleSubmit();
            }}
            className={styles.buttonNext}
          />
        </div>
      </div>
    </div>
  );
};
