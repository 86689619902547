export enum StateStyles {
	Error = 'error',
	ErrorFocused = 'error-focused',
	ErrorHovered = 'error-hovered',
	Focused = 'focused',
	Hover = 'hover',
	Disabled = 'disabled',
	Default = 'default',
}

interface StyleConfig {
	containerStyle?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
}

export const configStylesByState: Record<StateStyles, StyleConfig> = {
	[StateStyles.Error]: {
		inputStyle: {
			color: "#E41D36",
			backgroundColor: "#FFE3E1"
		},
		containerStyle: {
			borderColor: 'transparent'
		}
	},
	[StateStyles.ErrorFocused]: {
		containerStyle: {
			borderColor: '#E41D36',
			boxShadow: "0 0 5px rgba(255, 0, 0, 0.5)"
		},
		inputStyle: {
			color: "#E41D36",
			backgroundColor: "#FFE3E1"
		}
	},
	[StateStyles.ErrorHovered]: {
		containerStyle: {
			borderColor: 'transparent !important',
		},
		inputStyle: {
			backgroundColor: "#FFE3E1 !important"
		}
	},
	[StateStyles.Focused]: {
		containerStyle: {
			borderColor: '#000'
		},
		inputStyle: {
			backgroundColor: "#FFFFFF"
		}
	},
	[StateStyles.Hover]: {
		containerStyle: {
			borderColor: 'transparent'
		},
		inputStyle: {
			backgroundColor: "#f0f0f0"
		}
	},
	[StateStyles.Disabled]: {
		containerStyle: {
			borderColor: 'transparent'
		},
		inputStyle: {
			backgroundColor: "rgba(0, 0, 0, 0.04)"
		}
	},
	[StateStyles.Default]: {
		containerStyle: {
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: "rgb(230, 230, 230)",
			borderRadius: "6px",
			borderBottomLeftRadius: 3,
			borderTopLeftRadius: 3,
			boxShadow: "none",
		},
		inputStyle: {
			width: "100%",
			height: 32,
			border: "none",
			color: "#000",
			backgroundColor: "#FFFFFF",
		}
	}
};