import { Dispatch, SetStateAction } from "react";
import { FormField } from "../form-field/FormField";
import { AttachmentsDetailsError, OrgTypeEnum } from "~/typings/types";
import { CheckboxField } from "~/components";
import cn from "classnames";
import { URL_OFERTA, URL_PRIVACY, URL_TARIF } from "~/configs/consts";

import styles from "./AcceptPolitics.module.scss";

export const GetAcceptsPoliticsTitle = ({ orgType }: any) => {
  const text = "Я ознакомлен и принимаю в полном объеме условия предоставления услуг, положения"
  return (
    <p className={styles.text}>
      {text}{" "}
      <a href={URL_OFERTA} target="_blank" className={styles.textBlueLink}>
        Оферты
      </a>
      ,{" "}
      <a href={URL_TARIF} target="_blank" className={styles.textBlueLink}>
        Тарифов
      </a>{" "}
      и{" "}
      <a href={URL_PRIVACY} target="_blank" className={styles.textBlueLink}>
        Политики конфиденциальности
      </a>{" "}
      .
    </p>
  );
};

interface Props {
  acceptPoliticsError: AttachmentsDetailsError;
  isAcceptPolitics: boolean;
  setIsAcceptPolitics: Dispatch<SetStateAction<boolean>>;
  setAcceptPoliticsError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  orgType?: OrgTypeEnum;
  containerClassName?: string;
}

export const AcceptPolitics = ({
  acceptPoliticsError,
  isAcceptPolitics,
  setIsAcceptPolitics,
  setAcceptPoliticsError,
  orgType,
  ...props
}: Props) => {
  return (
    <div className={cn(styles.container, props.containerClassName)}>
      <FormField name="accept-politics" renderError={acceptPoliticsError}>
        <CheckboxField
          fieldName="accept-politics"
          name={isAcceptPolitics}
          checked={isAcceptPolitics}
          title={<GetAcceptsPoliticsTitle orgType={orgType} />}
          styleIndicatorProps={{ top: 4 }}
          onChange={(event: any) => {
            if (!isAcceptPolitics) {
              setAcceptPoliticsError(null);
            }
            setIsAcceptPolitics(event);
          }}
        />
      </FormField>
    </div>
  );
};
