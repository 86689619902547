// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { clientApi, getTypeQuestionnaires } from "~/utils";
import { postDraftAttachmentsDetails as postDraftAttachmentsDetailsPartner } from "~/pages/questionnaires/v1/partner/api/apiMethods";
import { AttachmentsDetailsError, ClientId, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";

import { useUploadLogoFile, useUploadPassportFile } from ".";
import { postDraftAttachmentsDetails as postDraftAttachmentsDetailsCustomer } from "../../v2/customer/api/apiMethods";

const fetchFileById = (fileId: any, setFiles: any, setFileIds: any) => {
  const id = Array.isArray(fileId)
    ? fileId.length > 0 ? fileId[0] : null
    : fileId;

  if (id) {
    clientApi
      .get(`/api/questionnaire/v1/helper/file/?file_id=${id}`)
      .then(res => {
        setFiles((prev: any) => [
          ...prev,
          new File([], res.data.data.filename_original),
        ]);
        setFileIds((prev: any) => [...prev, res.data.data.file_id]);
      });
  }
};

// TODO: need refactor
// Функия для валидации которая приходит с бека, если приходит ошибки то показываем ошибку под Файламм
const getErrorAttachmentDetails = (dataError: any, setLogoError: any, setPassportError: any, setDocumentAgreement1Error: any, setDocumentAgreement2Error: any, setPassportMainFilesError: any, setPassportRegistrationFilesError: any) => {
  if (!dataError || !Array.from(dataError) || !dataError.length) {
    return;
  }
  const isPartnerTypeQuestionnaires = getTypeQuestionnaires() === "partner";
  console.log(dataError)
  dataError.forEach((error: any) => {
    const fieldName = error.data.target;
    if (fieldName === NamesOfFieldEnum.LogoFileId || (isPartnerTypeQuestionnaires && fieldName === NamesOfFieldEnum.PassportMainFileId)) {
      setLogoError({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.PassportFileId || (isPartnerTypeQuestionnaires && fieldName === NamesOfFieldEnum.PassportRegistrationFileId)) {
      setPassportError({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.DocumentAgreement1FileId) {
      setDocumentAgreement1Error({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.DocumentAgreement2FileId) {
      setDocumentAgreement2Error({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.PassportMainFileId) {
      setPassportMainFilesError({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.PassportRegistrationFileId) {
      setPassportRegistrationFilesError({
        has: 1,
        message: error.message
      });
    }
  });
};

type UseAttachmentsDetails = {
  clientId: ClientId;
  orgType: OrgTypeEnum;
  initialLogoFileId: number[];
  initialPassportFileId: number[];
  initialPassportMainFileId: number[];
  initialPassportRegistrationFileId: number[];
  initialDocumentAgreement1FileId: number[];
  initialDocumentAgreement2FileId: number[];
  isAcceptPoliticsDefault?: boolean;
  urlHelperFile: string;
};

export const useAttachmentsDetails = ({
  clientId,
  orgType,
  initialLogoFileId,
  initialPassportFileId,
  initialPassportMainFileId,
  initialPassportRegistrationFileId,
  initialDocumentAgreement1FileId,
  initialDocumentAgreement2FileId,
  urlHelperFile,
  isAcceptPoliticsDefault = true,
}: UseAttachmentsDetails) => {
  /** Запрашиваем сохранненые файлы ранее при маунте компонента и получаем id файлов */
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      initialLogoFileId?.[0] &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialLogoFileId[0]}`)
        .then(res => {
          setLogoFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setLogoFileId(prev => [...prev, res.data.data.file_id]);
        }),

      (initialLogoFileId != null && !(Array.isArray(initialLogoFileId) && initialLogoFileId?.length == 0)) &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialLogoFileId}`)
        .then(res => {
          setLogoFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setLogoFileId(prev => [...prev, res.data.data.file_id]);
        }),
        
      (initialPassportMainFileId != null && !(Array.isArray(initialPassportMainFileId) && initialPassportMainFileId?.length == 0)) &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialPassportMainFileId}`)
        .then(res => {
          setPassportMainFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setPassportMainFileId(prev => [...prev, res.data.data.file_id]);
        }),

      (initialPassportRegistrationFileId != null && !(Array.isArray(initialPassportRegistrationFileId) && initialPassportRegistrationFileId?.length == 0)) &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialPassportRegistrationFileId}`)
        .then(res => {
          setPassportRegistrationFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setPassportRegistrationFileId(prev => [...prev, res.data.data.file_id]);
        }),
      fetchFileById(
        initialDocumentAgreement1FileId,
        setDocumentAgreement1Files,
        setDocumentAgreement1FileId
      ),
      fetchFileById(
        initialDocumentAgreement2FileId,
        setDocumentAgreement2Files,
        setDocumentAgreement2FileId
      )
    ]).finally(() => setIsLoading(false));
  }, []);
  /** ------ end ------ */

  /** ------ Отправляем файл на сервер после загрузки <input type="file" /> ------ */
  const [logoFiles, setLogoFiles] = useState<File[]>([]);
  const [documentAgreement1Files, setDocumentAgreement1Files] = useState<File[]>([]);
  const [documentAgreement2Files, setDocumentAgreement2Files] = useState<File[]>([]);
  const [passportFiles, setPassportFiles] = useState<File[]>([]);
  const [passportMainFiles, setPassportMainFiles] = useState<File[]>([]);
  const [passportRegistrationFiles, setPassportRegistrationFiles] = useState<File[]>([]);
  const [logoError, setLogoError] = useState<AttachmentsDetailsError>(null);
  const [documentAgreement1Error, setDocumentAgreement1Error] = useState<AttachmentsDetailsError>(null);
  const [documentAgreement2Error, setDocumentAgreement2Error] = useState<AttachmentsDetailsError>(null);
  const [passportMainFilesError, setPassportMainFilesError] = useState<AttachmentsDetailsError>(null);
  const [passportRegistrationFilesError, setPassportRegistrationFilesError] = useState<AttachmentsDetailsError>(null);
  const [passportError, setPassportError] = useState<AttachmentsDetailsError>(null);
  const [isFirstLoadingFile, setIsFirstLoadingFile] = useState(false); // Флаг который изменятся когда происходит хоть одно с действием с файлами, удаление/добавление

  const isErrorAttachmentsDetails = Boolean(logoError) || Boolean(passportError) || Boolean(documentAgreement1Error) || Boolean(documentAgreement2Error) || Boolean(passportMainFilesError) || Boolean(passportRegistrationFilesError);

  const {
    filesId: logoFileId,
    setFilesId: setLogoFileId,
    isLoading: isLoadingUploadLogoFile
  } = useUploadLogoFile({ logoFiles, setLogoError, setIsFirstLoadingFile, urlHelperFile });

  const {
    filesId: documentAgreement1FileId,
    setFilesId: setDocumentAgreement1FileId,
    isLoading: isLoadingUploadDocumentAgreement1File
  } = useUploadLogoFile({ logoFiles: documentAgreement1Files, setLogoError: setDocumentAgreement1Error, setIsFirstLoadingFile, urlHelperFile });

  const {
    filesId: documentAgreement2FileId,
    setFilesId: setDocumentAgreement2FileId,
    isLoading: isLoadingUploadDocumentAgreement2File
  } = useUploadLogoFile({ logoFiles: documentAgreement2Files, setLogoError: setDocumentAgreement2Error, setIsFirstLoadingFile, urlHelperFile });

  const {
    filesId: passportFileId,
    setFilesId: setPassportFileId,
    isLoading: isLoadingUploadPassportFile
  } = useUploadPassportFile({
    passportFiles,
    setPassportError,
    setIsFirstLoadingFile,
    urlHelperFile
  });

  const {
    filesId: passportMainFileId,
    setFilesId: setPassportMainFileId,
    isLoading: isLoadingPassportMainFileId
  } = useUploadLogoFile({ logoFiles: passportMainFiles, setLogoError: setPassportMainFilesError, setIsFirstLoadingFile, urlHelperFile });

  const {
    filesId: passportRegistrationFileId,
    setFilesId: setPassportRegistrationFileId,
    isLoading: isLoadingPassportRegistrationFile
  } = useUploadLogoFile({ logoFiles: passportRegistrationFiles, setLogoError: setPassportRegistrationFilesError, setIsFirstLoadingFile, urlHelperFile });


  /** ------ end ------ */

  /** ------ Сохраняем в черновик отправленные файла по id ------ */
  const [isLoadingAttachmentsDetails, setIsLoadingAttachmentsDetails] = useState(false);

  const isPartnerTypeQuestionnaires = getTypeQuestionnaires() === "partner";
  // Надо рефакторинг всего этого дела связанного с разделением анкет на партнеров и клиентов
  const keyFieldFile1 = isPartnerTypeQuestionnaires ? NamesOfFieldEnum.PassportMainFileId : NamesOfFieldEnum.LogoFileId;
  const keyFieldFile2 = isPartnerTypeQuestionnaires
    ? NamesOfFieldEnum.PassportRegistrationFileId
    : NamesOfFieldEnum.PassportFileId;

  const postDraftAttachmentsDetails = isPartnerTypeQuestionnaires
    ? postDraftAttachmentsDetailsPartner
    : postDraftAttachmentsDetailsCustomer;

  const handleRequestAttachmentsDetails = useCallback(
    async ({ orgType }: { orgType: OrgTypeEnum }) => {
      try {
        setIsLoadingAttachmentsDetails(true);

        const responsePostDraftAttachmentsDetails = await postDraftAttachmentsDetails({
          data: {
            id: clientId,
            is_presave_mode: false,
            fields: {
              ...(passportMainFileId?.length && {
                [NamesOfFieldEnum.PassportMainFileId]: passportMainFileId[0]
              }),
              ...(passportRegistrationFileId?.length && {
                [NamesOfFieldEnum.PassportRegistrationFileId]: passportRegistrationFileId[0]
              }),
              // ...(passportFileId?.length && {
              //   [keyFieldFile2]: isPartnerTypeQuestionnaires ? passportFileId[0] : passportFileId
              // }),
              ...(documentAgreement1FileId?.length && !isPartnerTypeQuestionnaires && {
                [NamesOfFieldEnum.DocumentAgreement1FileId]: documentAgreement1FileId[0]
              }),
              ...(documentAgreement2FileId?.length && !isPartnerTypeQuestionnaires && {
                [NamesOfFieldEnum.DocumentAgreement2FileId]: documentAgreement2FileId[0]
              }),
              [NamesOfFieldEnum.OrgType]: orgType,
              [NamesOfFieldEnum.IsOffertAccepted]: 1
            }
          }
        });
        setIsLoadingAttachmentsDetails(false);
        if (
          responsePostDraftAttachmentsDetails?.success === 0 &&
          responsePostDraftAttachmentsDetails?.errors &&
          responsePostDraftAttachmentsDetails.errors.length > 0
        ) {
          getErrorAttachmentDetails(
            responsePostDraftAttachmentsDetails.errors,
            setLogoError,
            setPassportError,
            setDocumentAgreement1Error,
            setDocumentAgreement2Error,
            setPassportMainFilesError,
            setPassportRegistrationFilesError
          );
          return 0; // возвращаем статус ошибки валидации
        }
      } catch (error) {
        throw error;
      }
    },
    [logoFileId, passportFileId, documentAgreement1FileId, documentAgreement2FileId, passportMainFileId, passportRegistrationFileId]
  );
  /** ------ end ------ */


  useEffect(() => {
    if (isFirstLoadingFile) {
      console.log('delete',)
      postDraftAttachmentsDetails({
        data: {
          id: clientId,
          is_presave_mode: true,
          fields: {
            // ...(isPartnerTypeQuestionnaires && {
            //   [keyFieldFile1]: logoFileId[0],
            //   [keyFieldFile2]: passportFileId[0],

            // }),
            [NamesOfFieldEnum.DocumentAgreement1FileId]: documentAgreement1FileId?.length ? documentAgreement1FileId[0] : null,
            [NamesOfFieldEnum.PassportMainFileId]: passportMainFileId?.length ? passportMainFileId[0] : null,
            [NamesOfFieldEnum.PassportRegistrationFileId]: passportRegistrationFileId?.length ? passportRegistrationFileId[0] : null,
            [NamesOfFieldEnum.OrgType]: orgType,
            [NamesOfFieldEnum.IsOffertAccepted]: 1
          }
        }
      });
    }
  }, [logoFileId, passportFileId, documentAgreement1FileId, documentAgreement2FileId, passportMainFileId, passportRegistrationFileId])

  const [isAcceptPolitics, setIsAcceptPolitics] = useState(isAcceptPoliticsDefault);
  const [acceptPoliticsError, setAcceptPoliticsError] = useState<AttachmentsDetailsError>(null);

  return {
    isLoadingMount: isLoading,
    isLoadingLogo: isLoadingUploadLogoFile,
    isLoadingPassport: isLoadingUploadPassportFile,
    isLoadingAttachmentsDetails,
    isErrorAttachmentsDetails,
    logoFileId,
    logoFiles,
    setLogoFileId,
    setLogoFiles,
    passportFileId,
    passportFiles,
    setPassportFileId,
    setPassportFiles,
    handleRequestAttachmentsDetails,
    logoError,
    passportError,
    setLogoError,
    setPassportError,
    isAcceptPolitics,
    setIsAcceptPolitics,
    acceptPoliticsError,
    setAcceptPoliticsError,
    setIsFirstLoadingFile,
    isLoadingDocumentAgreement1File: isLoadingUploadDocumentAgreement1File,
    isLoadingDocumentAgreement2File: isLoadingUploadDocumentAgreement2File,
    documentAgreement1FileId,
    documentAgreement2FileId,
    documentAgreement1Files,
    documentAgreement2Files,
    setDocumentAgreement1Files,
    setDocumentAgreement2Files,
    setDocumentAgreement1FileId,
    setDocumentAgreement2FileId,
    documentAgreement1Error,
    documentAgreement2Error,
    setDocumentAgreement1Error,
    setDocumentAgreement2Error,
    passportMainFiles,
    setPassportMainFiles,
    passportRegistrationFiles,
    setPassportRegistrationFiles,
    passportMainFilesError,
    setPassportMainFilesError,
    passportRegistrationFilesError,
    setPassportRegistrationFilesError,
    passportMainFileId,
    passportRegistrationFileId,
    setPassportMainFileId,
    isLoadingPassportMainFileId,
    setPassportRegistrationFileId,
    isLoadingPassportRegistrationFile
  };
};
