import cn from "classnames";
import { ButtonHTMLAttributes, CSSProperties, MouseEventHandler, ReactNode } from "react";
import { BUTTON_NEXT_ID } from "~/configs/consts";
import { LoadingContainer } from "~/containers/loading-сontainer";

import styles from "./CustomButton.module.scss";

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  isLoading?: boolean;
  icon?: ReactNode;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  name,
  style,
  onClick,
  className,
  isLoading = false,
  type = "button",
  disabled = false,
  icon,
  id = BUTTON_NEXT_ID,
  ...props
}) => {
  return (
    <button
      {...props}
      style={style}
      onClick={onClick}
      className={cn(styles.button, className, {
        [styles.buttonDisabled]: isLoading || disabled,
      })}
      type={type}
      disabled={disabled}
      id={id}
    >
      <LoadingContainer isLoading={isLoading}>
        <>
          {name}
          {icon && <span className={styles.icon}>{icon}</span>}
        </>
      </LoadingContainer>
    </button>
  );
};
