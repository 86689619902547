import React, { useState, type FC } from "react";
import { TypeSuggestionData } from "~/typings/types";
import { AutofillFieldButton, ErrorField, TitleField } from "~/components";
import { AutocompleteInput, type AutocompleteInputProps } from "./AutocompleteInput";
import { RenderError } from "~/components/error-field";

import styles from "./Autocomplete.module.scss";

export type GetErrorSuggestions = {
  code: string;
  message: string;
} | null;

interface FormFieldAutocompleteProps<T extends TypeSuggestionData> extends Omit<AutocompleteInputProps<T>, "setErrorGetSuggestions"> {
  titleField?: string;
  required?: boolean;
  tooltip?: string;
  isColon?: boolean;
  styleContainerTitleField?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
  placeholder?: string;
  children?: React.ReactNode;
  renderError?: RenderError;
  triggerAutofill?: string[];
  triggerAutofillForAddressFiasId?: string[];
};

export const FormFieldAutocomplete = <T extends TypeSuggestionData>({
  name,
  titleField,
  required,
  tooltip,
  isColon,
  styleContainerTitleField,
  styleContainer,
  renderError,
  triggerAutofill,
  triggerAutofillForAddressFiasId,
  ...propsAutocompleteInput
}: FormFieldAutocompleteProps<T>) => {
  const [errorGetSuggestions, setErrorGetSuggestions] = useState<GetErrorSuggestions>();

  const customErrorField =
    (renderError?.has && renderError) || (errorGetSuggestions && { has: 1, message: errorGetSuggestions.message });

  return (
    <div className={styles.containerField} style={styleContainer}>
      {titleField && (
        <TitleField
          name={titleField}
          required={required}
          tooltip={tooltip}
          isColon={isColon}
          styleContainer={styleContainerTitleField}
        />
      )}

      <div className={styles.containerInput}>
        <AutocompleteInput<T>
          {...propsAutocompleteInput}
          name={name}
          propsSpy={propsAutocompleteInput.propsSpy}
          setErrorGetSuggestions={setErrorGetSuggestions}
          customError={!!customErrorField}
        />
      </div>

      <AutofillFieldButton
        triggerAutofill={triggerAutofill}
        triggerAutofillForAddressFiasId={triggerAutofillForAddressFiasId}
      />
      <ErrorField name={name} renderError={customErrorField} />
    </div>
  );
};
