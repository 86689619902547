import { FormSpy } from "react-final-form";
import { TitlePage } from "~/components";
import {
  composeValidators,
  required,
  testRegExp
} from "~/utils/validate";
import { Divider } from "antd";
import {
  FormField,
  FormFieldBic
} from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfSelfEmployed, configTitleFieldsOfBank } from "../configTitleFields";
import { GET_BANK_INFO } from "~/pages/questionnaires/v1/customer/api/apiUrls";
import { V2Wizard } from "../../../../shared/wizard";
import { FullWidthRowFormContainer } from "~/pages/questionnaires/shared/form-field";

import styles from "./CommonSteps.module.scss";

interface CompanyDetailsProps {
  clientId: string;
}

export const CompanyDetails = ({ clientId }: CompanyDetailsProps) => {
  return (
    <V2Wizard.Page>
      <TitlePage name="Ваши реквизиты для вывода средств" number="3" description="Вставьте БИК банка - остальные данные мы загрузим автоматически" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>

        <FullWidthRowFormContainer>
          <FormSpy>
            {propsSpy => {
              return (
                <FormFieldBic
                  titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.BankBic]}
                  name={NamesOfFieldEnum.BankBic}
                  required
                  // maxLength={9}
                  mask="04"
                  validate={composeValidators(
                    required(),
                    testRegExp(/^04(\d){7}$/g, 'БИК должен начинаться на "04" и состоять из 9 цифр')
                  )}
                  configNameFieldsModalSuggestion={configTitleFieldsOfBank}
                  propsSpy={propsSpy}
                  clientId={clientId}
                  tooltip="БИК — уникальный код банка. Он состоит из девяти цифр"
                  urlGetBankInfo={GET_BANK_INFO}
                />
              );
            }}
          </FormSpy>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.BankName]}
            name={NamesOfFieldEnum.BankName}
            required
            validate={composeValidators(required())}
            orderNumber="3"
          />
        </FullWidthRowFormContainer>

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.SettlementAccount]}
          name={NamesOfFieldEnum.SettlementAccount}
          maxLength={20}
          mask="408"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^408(\d){17}$/g,
              'Номер расчётного счёта должен начинаться на "408" и состоять из 20 цифр'
            )
          )}
          orderNumber="4"
        />

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.CorrespondentAccount]}
          name={NamesOfFieldEnum.CorrespondentAccount}
          maxLength={20}
          mask="301"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^301(\d){17}$/g,
              'Номер корреспондентского счёта должен начинаться на "301" и состоять из 20 цифр'
            )
          )}
          orderNumber="5"
        />

      </div>
    </V2Wizard.Page>
  );
};
