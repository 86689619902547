import { NamesOfFieldEnum, ConfigNamesFields, NamesOfFieldBankInfoEnum } from "~/typings/types";

export const configTitleFieldsOfIndividualEntrepreneur: ConfigNamesFields = {
  [NamesOfFieldEnum.OrgType]: "ИП",
  [NamesOfFieldEnum.Name]: "Ф.И.О.",
  [NamesOfFieldEnum.Inn]: "ИНН",
  [NamesOfFieldEnum.Ogrn]: "ОГРН",
  [NamesOfFieldEnum.Ogrnip]: "ОГРНИП",
  [NamesOfFieldEnum.Kpp]: "КПП",
  [NamesOfFieldEnum.BankName]: "Название банка",
  [NamesOfFieldEnum.SettlementAccount]: "Расчётный счёт",
  [NamesOfFieldEnum.CorrespondentAccount]: "Корреспондентский счёт",
  [NamesOfFieldEnum.BankBic]: "БИК",
  [NamesOfFieldEnum.LegalAddress]: "Адрес регистрации (как в паспорте)",
  [NamesOfFieldEnum.PostalAddress]: "Почтовый адрес",
  [NamesOfFieldEnum.Vat]: "Являетесь ли плательщиком НДС?",
  [NamesOfFieldEnum.VatMode]: "Вы работаете как ИП на НПД?",
  [NamesOfFieldEnum.SignatureInfo]: "Должность и Ф.И.О. лица, подписывающего договор",
  [NamesOfFieldEnum.LprName]: "Ф.И.О. Ген. директора",
  [NamesOfFieldEnum.LprPhone]: "Мобильный телефон",
  [NamesOfFieldEnum.BuhName]: "Ф.И.О. Бухгалтера",
  [NamesOfFieldEnum.BuhPhone]: "Мобильный телефон",
  [NamesOfFieldEnum.TechName]: "Ф.И.О. Тех.специалиста",
  [NamesOfFieldEnum.TechPhone]: "Мобильный телефон",
  [NamesOfFieldEnum.PayformPhone]: "Номер телефона для покупателей",
  [NamesOfFieldEnum.PayformEmail]: "Email для покупателей",
  [NamesOfFieldEnum.PayformLinks]:
    "Ссылка на сайт или соцсеть с описанием ваших услуг",
  [NamesOfFieldEnum.CrmName]: "Ф.И.О.",
  [NamesOfFieldEnum.CrmPhone]: "Мобильный телефон",
  [NamesOfFieldEnum.CrmEmail]: "E-mail",
  [NamesOfFieldEnum.CrmBirthday]: "Дата рождения",
  [NamesOfFieldEnum.CrmSocialLinks]: "Ссылка на профиль в соцсетях",
  [NamesOfFieldEnum.PaymentNotificationEmail]: "E-mail для уведомлений об оплате",
  [NamesOfFieldEnum.ProductType]: "Что будете продавать?",
  [NamesOfFieldEnum.ProductName]: "Название вашего магазина",
  [NamesOfFieldEnum.PayformName]: "Укажите название вашей платёжной страницы для покупателей (латиницей)",
  [NamesOfFieldEnum.EducationalMaterialsPlatform]:
    "На какой платформе размещены учебные материалы и информация о ваших услугах?",
  [NamesOfFieldEnum.PayformPaymentMethods]:
    "Какие методы оплат вы хотите сделать доступными для покупателей?",
  [NamesOfFieldEnum.ClientIntegrationInfo]:
    "Перечислите сервисы и платформы с которыми потребуются интеграции для осуществления продаж",
  [NamesOfFieldEnum.ShopName]: "Название вашего магазина",
  [NamesOfFieldEnum.ProductDescription]: "Описание вашей деятельности",
  [NamesOfFieldEnum.LogoFileId]: "Ваш логотип",
  [NamesOfFieldEnum.LogoMainFileId]: "Ваш логотип",
  [NamesOfFieldEnum.PassportFileId]: "Загрузите паспорт",
  [NamesOfFieldEnum.ClientFrom]: "Откуда вы о нас узнали?",
  [NamesOfFieldEnum.ClientPromocode]: "Промокод партнера",
  [NamesOfFieldEnum.ClientLengthOfService]: "Давно ли вы на рынке онлайн-образования?",
  [NamesOfFieldEnum.IsHaveEducationalLicense]: "Есть ли у вас образовательная лицензия?",
  [NamesOfFieldEnum.PaymentMethodProdamusSubscription]: "Каким способом вам удобней оплатить подключение к Продамус?",
  [NamesOfFieldEnum.DocumentAgreement1FileId]: "Загрузите договор оказания услуг или оферту"
};

export const configTitleFieldsOfBank = {
  [NamesOfFieldBankInfoEnum.BankName]: "Название банка",
  [NamesOfFieldBankInfoEnum.BankInn]: "ИНН банка",
  [NamesOfFieldBankInfoEnum.BankBic]: "БИК банка",
  [NamesOfFieldBankInfoEnum.BankKpp]: "КПП банка",
  [NamesOfFieldBankInfoEnum.BankCorrespondentAccount]: "Корреспондентский счёт банка"
};

export const optionsSelectClientFrom = [
  {
    label: 'От партнёра Prodamus (подключение по промокоду)',
    value: 'partner_promo',
  },
  {
    label: 'По совету знакомого, коллеги или члена команды',
    value: 'referral',
  },
  {
    label: 'По рекомендации блогера',
    value: 'blogger_recommendation',
  },
  {
    label: 'Через поиск в Яндекс или Google',
    value: 'search_engine',
  },
  {
    label: 'Из соцсетей',
    value: 'social_media',
  },
  {
    label: 'Из рекламы',
    value: 'advertisement',
  },
  {
    label: 'Из чатов или обсуждений в группах',
    value: 'group_chats',
  },
  {
    label: 'На обучающих курсах',
    value: 'training_courses',
  },
  {
    label: 'На мероприятии (конференции, вебинары и т.д.)',
    value: 'events',
  },
  {
    label: 'Пользовались Prodamus ранее',
    value: 'previous_user',
  },
  {
    label: 'Пользовались другими продуктами экосистемы Prodamus',
    value: 'other_prodamus_products',
  },
  {
    label: 'Переоформление (например, с СМЗ на ИП и наоборот)',
    value: 'reissue',
  },
  {
    label: 'Другое (укажите ниже)',
    value: 'other',
  },
];