import { Dispatch, SetStateAction, useState } from "react";
import cn from "classnames";
import { CustomButton, PhoneField } from "~/components";
import { ClientId, NamesOfFieldEnum, StatusConfirmationCode } from "~/typings/types";
import { ModalToggle } from "~/hooks/useModal";
import { FormField } from "../../form-field/FormField";
import { clientApi, getWithPrefixPhone } from "~/utils";
import { INITIAL_REQUEST_CODE } from "~/configs/consts";

import styles from "./ModalContent.module.scss";
import { CHANGE_PHONE } from "~/pages/questionnaires/v1/customer/api/apiUrls";

type Props = {
  clientId: ClientId;
  oldPhone: string;
  closeModal: ModalToggle;
  setNewPhone: Dispatch<SetStateAction<string>>;
  setStatusConfirmationCode: Dispatch<SetStateAction<StatusConfirmationCode>>;
  setCountTryStartTimer: Dispatch<SetStateAction<number>>;
  setErrorManyAttempts: Dispatch<SetStateAction<string | false>>;
  stopTimer: () => void;
};

export const ModalContentNewPhone = ({
  clientId,
  oldPhone,
  closeModal,
  setNewPhone,
  setStatusConfirmationCode,
  setCountTryStartTimer,
  stopTimer,
  setErrorManyAttempts
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [valuePhone, setValuePhone] = useState("");
  const [errorPhone, setErrorPhone] = useState<null | { has: 1; message: string }>(null);

  const handleChangePhone = (value: string) => {
    setValuePhone(value);
  };

  const handleSubmit = async () => {
    try {
      setErrorPhone(null);

      setIsLoading(true);

      await clientApi.post(CHANGE_PHONE, {
        id: clientId,
        phone_old: getWithPrefixPhone(oldPhone),
        phone_new: getWithPrefixPhone(valuePhone)
      });

      // После изменения номера телефона, нужно форму ввода кода вернуть в начальное состояние
      stopTimer();
      setCountTryStartTimer(0);
      setStatusConfirmationCode(INITIAL_REQUEST_CODE);
      setErrorManyAttempts(false);

      setNewPhone(valuePhone);
      setIsLoading(false);
      closeModal();
    } catch (error: any) {
      setIsLoading(false);
      setErrorPhone({
        has: 1,
        message: error?.message
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>Изменение номера</p>
        <button type="button" className={styles.buttonClose} onClick={closeModal} />
      </div>
      <div className={styles.content}>
        {/* <FormField
          titleField="Номер телефона"
          name={NamesOfFieldEnum.CrmPhone}
          required
          renderError={errorPhone}
        >
          <PhoneField
            input={{
              name: NamesOfFieldEnum.CrmPhone,
              value: valuePhone,
              onChange: handleChangePhone
            }}
          />
        </FormField> */}
      </div>
      <div className={styles.footer}>
        <CustomButton
          name="Продолжить"
          type="button"
          className={cn(styles.buttonSubmit)}
          onClick={handleSubmit}
          disabled={!valuePhone || isLoading}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
