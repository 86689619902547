import { useEffect } from "react";
import { NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";
import { DefaultPage, useAttachmentsDetails } from "~/pages/questionnaires/shared";
import {
  CompanyDetails,
  AccountDetails,
  PayformTechDetails,
  AdditionalDetails,
  AttachmentsDetails,
} from "./steps";
import { HELPER_FILE } from "~/pages/questionnaires/v1/customer/api/apiUrls";
import { V2Wizard } from "../../../shared/wizard";

const COUNT_UPLOAD_LOGO_FILE = 1;

export const CompanyStep = ({
  clientId,
  paymentMethod,
  educationalPlatform,
  queryPromocode,
  setOrgType,
  productType,
  ...sharedStepProps
}: any) => {
  useEffect(() => {
    setOrgType(OrgTypeEnum.Company);
  }, []);

  const attachmentsDetails = useAttachmentsDetails({
    clientId,
    orgType: OrgTypeEnum.Company,
    initialLogoFileId: sharedStepProps.initialValues.logo_main_file_id,
    initialPassportFileId: sharedStepProps.initialValues.passport_file_id,
    initialPassportMainFileId: sharedStepProps.initialValues.passport_main_file_id,
    initialPassportRegistrationFileId: sharedStepProps.initialValues.passport_registration_file_id,
    initialDocumentAgreement1FileId: sharedStepProps.initialValues.document_agreement1_file_id,
    initialDocumentAgreement2FileId: sharedStepProps.initialValues.document_agreement2_file_id,
    urlHelperFile: HELPER_FILE
  });

  return (
    <V2Wizard
      {...sharedStepProps}
      logoFileId={attachmentsDetails.logoFileId}
      documentAgreement1FileId={attachmentsDetails.documentAgreement1FileId}
      documentAgreement2FileId={attachmentsDetails.documentAgreement2FileId}
      handleRequestAttachmentsDetails={attachmentsDetails.handleRequestAttachmentsDetails}
      isLoadingAttachmentsDetails={attachmentsDetails.isLoadingAttachmentsDetails}
      isErrorAttachmentsDetails={attachmentsDetails.isErrorAttachmentsDetails}
      isAcceptPolitics={attachmentsDetails.isAcceptPolitics}
      acceptPoliticsError={attachmentsDetails.acceptPoliticsError}
      setAcceptPoliticsError={attachmentsDetails.setAcceptPoliticsError}
    >
      <DefaultPage />
      <AccountDetails
        clientId={clientId}
        orgType={sharedStepProps.orgType}
        isShowAdditionalFieldsInitially={!!sharedStepProps.initialValues[NamesOfFieldEnum.LprName]}
      />
      <CompanyDetails clientId={clientId} />
      <PayformTechDetails
        {...attachmentsDetails}
        COUNT_UPLOAD_LOGO_FILE={COUNT_UPLOAD_LOGO_FILE}
        paymentMethod={paymentMethod}
        educationalPlatform={educationalPlatform}
        productType={productType}
        typeRequestCheckPayformName={sharedStepProps.typeRequestCheckPayformName}
        callbackCheckPayformName={sharedStepProps.callbackCheckPayformName}
      />
      <AdditionalDetails queryPromocode={queryPromocode} />
      <AttachmentsDetails
        {...attachmentsDetails}
        COUNT_UPLOAD_LOGO_FILE={COUNT_UPLOAD_LOGO_FILE}
        orgType={OrgTypeEnum.IndividualEntrepreneur}
      />
    </V2Wizard>
  );
};
