import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { defaultAxiosRequestConfig } from "~/api/apiMethods";
import { AttachmentsDetailsError } from "~/typings/types";
import { clientApi } from "~/utils";

const configRequest = {
  ...defaultAxiosRequestConfig,
  headers: {
    "Content-Type": "multipart/form-data"
  }
};

type Props = {
  logoFiles: File[];
  setLogoError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  setIsFirstLoadingFile: Dispatch<boolean>;
  urlHelperFile: string;
};

/** Используется если загрузить нужно только 1 файл */
export const useUploadLogoFile = ({
  logoFiles,
  setLogoError,
  setIsFirstLoadingFile,
  urlHelperFile
}: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filesId, setFilesId] = useState<number[]>([]);

  useEffect(() => {
    if (isMounted) {
      try {
        if (!logoFiles.length || !(logoFiles.length && filesId.length < logoFiles.length)) {
          return;
        }
        setIsLoading(true);
        let formData = new FormData();
        logoFiles.forEach(file => formData.append("file", file));

        clientApi
          .post(urlHelperFile, formData, configRequest)
          .then(response => {
            const fileId = response.data.data.file_id;
            setFilesId(prev => [...prev, fileId]);
            setIsFirstLoadingFile(true);
          })
          .catch(error => {
            setLogoError({
              has: 1,
              message: error.response.data.error?.[0].message
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
      }
    } else {
      setIsMounted(true);
    }
  }, [logoFiles]);

  return { filesId, setFilesId, isLoading };
};
