import cn from "classnames";

import styles from "./FormField.module.scss";
import { CSSProperties, ReactNode } from "react";


interface FullWidthRowFormContainerProps {
	isFullWidthRow?: boolean
	styleContainer?: CSSProperties;
	children: ReactNode
}

export const FullWidthRowFormContainer = ({ isFullWidthRow = true, styleContainer, children }: FullWidthRowFormContainerProps) => {
	const classNameContainer = cn(styles.containerField, {
		[styles.fullWidthRow]: isFullWidthRow
	})
	return (
		<div style={styleContainer} className={classNameContainer}>
			{children}
		</div>
	)
}