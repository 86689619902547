import { Dispatch, useEffect, SetStateAction, ChangeEvent, useState } from "react";
import { Divider, Input, Spin } from "antd";
import { useFormState } from "react-final-form";
import { LoadingOutlined } from "@ant-design/icons";
import { ClientId, NamesOfFieldEnum, StatusConfirmationCode } from "~/typings/types";
import { Modal } from "~/components";
import { ModalContentNewPhone, ModalContentOverAttempt, ResendCode } from "./components";
import { useModal } from "~/hooks";
import { FormField } from "../form-field/FormField";
import { ErrorFieldFromBackend } from "~/api/apiMethods";
import { formatPhoneNumberRu } from "./utils/formatPhoneNumberRu";
import { KEY_CUSTOMER_CLIENT_ID, URL_OFERTA } from "~/configs/consts";

import styles from "./Confirmation.module.scss";
import { PENDING_REQUEST_CODE } from "~/configs/consts";
import { clientApi } from "~/utils";
import { CONFIRM_CODE, DRAFT_SAVE } from "~/pages/questionnaires/v1/customer/api/apiUrls";

const COUNT_MAX_CODE = 6;
const antIcon = <LoadingOutlined style={{ fontSize: 70, color: "#3d935e" }} spin />;

export type PropsConfirmation = {
  clientId: ClientId;
  errorOfCode: false | string;
  setErrorOfCode: Dispatch<SetStateAction<string | false>>;
  valueOfCode: string;
  setValueOfCode: Dispatch<SetStateAction<string>>;
  codeUUID: null | string;
  isLoadingGetRequestCode: boolean;
  setIsRenderSuccessPage: Dispatch<SetStateAction<boolean>>;
  setIsRenderErrorPage: Dispatch<SetStateAction<boolean>>;
  isRunningTimer: boolean;
  secondsTimer: number;
  countTryStartTimer: number;
  setCountTryStartTimer: Dispatch<SetStateAction<number>>;
  handleGetRequestCode: ({ status }: { status: StatusConfirmationCode }) => Promise<void>;
  errorManyAttempts: false | string;
  setErrorManyAttempts: Dispatch<SetStateAction<string | false>>;
  statusConfirmationCode: StatusConfirmationCode;
  setStatusConfirmationCode: Dispatch<SetStateAction<StatusConfirmationCode>>;
  stopTimer: () => void;
};

export const Confirmation = ({
  clientId,
  errorOfCode,
  setErrorOfCode,
  valueOfCode,
  setValueOfCode,
  codeUUID,
  isLoadingGetRequestCode,
  setIsRenderSuccessPage,
  setIsRenderErrorPage,
  isRunningTimer,
  secondsTimer,
  countTryStartTimer,
  setCountTryStartTimer,
  handleGetRequestCode,
  errorManyAttempts,
  setErrorManyAttempts,
  statusConfirmationCode,
  setStatusConfirmationCode,
  stopTimer
}: PropsConfirmation) => {
  const [newPhone, setNewPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { values } = useFormState();
  const valuePhone = values[NamesOfFieldEnum.CrmPhone];

  const stylesInput = {
    borderColor: errorOfCode ? "#E41D36" : "#E6E6E6",
    color: errorOfCode ? "#E41D36" : "#000",
    width: "100%"
  };

  const isDisabledInput =
    (valueOfCode.length === COUNT_MAX_CODE || !codeUUID) && !Boolean(errorOfCode);

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setValueOfCode(event.target.value);
    if (Boolean(errorOfCode)) {
      setErrorOfCode(false);
    }
  };

  const {
    isShow: isShowModal,
    open: openModal,
    toggle: toggleModal,
    close: closeModal
  } = useModal();

  const {
    isShow: isShowModalNewPhone,
    open: openModalNewPhone,
    toggle: toggleModalNewPhone,
    close: closeModalNewPhone
  } = useModal();

  useEffect(() => {
    if (valueOfCode.length === COUNT_MAX_CODE && codeUUID) {
      setIsLoading(true);
      clientApi
        .post(CONFIRM_CODE, {
          data: {
            code_uuid: codeUUID,
            code: valueOfCode
          }
        })
        .then(() => {
          clientApi
            .post(DRAFT_SAVE, { id: clientId })
            .then(() => {
              setIsRenderSuccessPage(true);
              localStorage.removeItem(KEY_CUSTOMER_CLIENT_ID);
            })
            .catch(() => {
              setIsRenderErrorPage(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((error: ErrorFieldFromBackend) => {
          setErrorOfCode(error.message);
          setIsLoading(false);
        });
    }
  }, [valueOfCode]);

  // TODO: refactor functional
  useEffect(() => {
    if (errorManyAttempts) {
      openModal();
    }
  }, [errorManyAttempts]);

  return (
    <>
      {isLoading && (
        <div className={styles.backgroundOverlay}>
          <Spin indicator={antIcon} />
        </div>
      )}
      <div className={styles.confirmationCode}>
        <div className={styles.container} style={{ minHeight: "auto" }}>
          <p className={styles.text}>Для завершения подтвердите номер.</p>
          <p className={styles.text}> Код подтверждения будет отправлен по СМС на номер:</p>
          <p className={styles.phone}>{formatPhoneNumberRu(newPhone || valuePhone)}</p>
          <button
            className={styles.buttonChangePhone}
            type="button"
            onClick={() => openModalNewPhone()}
          >
            Изменить номер
          </button>

          <div className={styles.inputCode}>
            {codeUUID && statusConfirmationCode === PENDING_REQUEST_CODE && (
              <>
                <FormField
                  name="confirmation-code"
                  titleField="Код подтверждения"
                  placeholder="Введите код подтверждения из СМС"
                  required
                  renderError={{
                    has: Boolean(errorOfCode),
                    message: errorOfCode
                  }}
                >
                  <Input
                    style={stylesInput}
                    value={valueOfCode}
                    maxLength={COUNT_MAX_CODE}
                    disabled={isDisabledInput}
                    onChange={handleOnChangeInput}
                    placeholder="Введите код подтверждения из СМС"
                  />
                </FormField>
                <div className={styles.text}>
                  Ввод кода смс подтверждает, что вы ознакомлены{" "}
                  <span className={styles.textBreak}>
                    с содержанием{" "}
                    <a href={URL_OFERTA} target="_blank" className={styles.textBlueLink}>
                      оферты
                    </a>{" "}
                    и принимаете ее в полном объеме
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <Divider style={{ margin: 0 }} />
        <div className={styles.container} style={{ minHeight: "auto", padding: "30px 70px" }}>
          <ResendCode
            handleGetRequestCode={handleGetRequestCode}
            isRunningTimer={isRunningTimer}
            secondsTimer={secondsTimer}
            countTryStartTimer={countTryStartTimer}
            isLoadingGetRequestCode={isLoadingGetRequestCode}
            statusConfirmationCode={statusConfirmationCode}
          />
        </div>

        <Modal isShow={isShowModal} toggle={toggleModal} isOutsideClick={false}>
          {errorManyAttempts && (
            <ModalContentOverAttempt
              errorManyAttempts={errorManyAttempts}
              closeModal={closeModal}
            />
          )}
        </Modal>

        <Modal isShow={isShowModalNewPhone} toggle={toggleModalNewPhone} isOutsideClick={true}>
          <ModalContentNewPhone
            clientId={clientId}
            oldPhone={valuePhone}
            closeModal={closeModalNewPhone}
            setNewPhone={setNewPhone}
            setCountTryStartTimer={setCountTryStartTimer}
            setStatusConfirmationCode={setStatusConfirmationCode}
            stopTimer={stopTimer}
            setErrorManyAttempts={setErrorManyAttempts}
          />
        </Modal>
      </div>
    </>
  );
};
