import { NamesOfFieldEnum } from "~/typings/types";

export const mockDataForm = {
  flat: {
    [NamesOfFieldEnum.Name]: "test: Ivanov Ivan Ivanovich",
    [NamesOfFieldEnum.Inn]: "999999999999",
    [NamesOfFieldEnum.Ogrn]: "1999999999999",
    [NamesOfFieldEnum.Ogrnip]: "99999999999999",
    [NamesOfFieldEnum.Kpp]: "99999",
    [NamesOfFieldEnum.BankName]: "bank name",
    [NamesOfFieldEnum.SettlementAccount]: "40899999999999999991",
    [NamesOfFieldEnum.CorrespondentAccount]: "30199999999999999991",
    [NamesOfFieldEnum.BankBic]: "041233333",
    [NamesOfFieldEnum.CrmBirthday]: "2025-01-15T17:00:00.000Z",
    [NamesOfFieldEnum.LegalAddress]: "Moscow",
    [NamesOfFieldEnum.PostalAddress]: "Moscow",
    [NamesOfFieldEnum.Vat]: "20",
    [NamesOfFieldEnum.SignatureInfo]: "Vladimir",
    [NamesOfFieldEnum.VatMode]: "orn",
    [NamesOfFieldEnum.LprName]: "Ivanov Ivan",
    [NamesOfFieldEnum.BuhName]: "Ivanov Ivan",
    [NamesOfFieldEnum.TechName]: "Ivanov Ivan",
    [NamesOfFieldEnum.PayformEmail]: "99999@mail.ru",
    [NamesOfFieldEnum.PayformLinks]: "test-link",
    [NamesOfFieldEnum.CrmName]: "Ivanov Ivan",
    // [NamesOfFieldEnum.CrmNameFirst]: "Ivan",
    // [NamesOfFieldEnum.CrmNameLast]: "Ivanov",
    // [NamesOfFieldEnum.CrmNamePatron]: "Ivanovovich",
    [NamesOfFieldEnum.CrmEmail]: "99999@mail.ru",
    [NamesOfFieldEnum.CrmSocialLinks]: "test-link",
    [NamesOfFieldEnum.PaymentNotificationEmail]: "99999@mail.ru",
    [NamesOfFieldEnum.ProductType]: "goods",
    [NamesOfFieldEnum.ProductName]: "test-product-name",
    [NamesOfFieldEnum.PayformName]: "test-payform-name",
    [NamesOfFieldEnum.ClientIntegrationInfo]: "999999",
    [NamesOfFieldEnum.ShopName]: "999999",
    [NamesOfFieldEnum.ProductDescription]: "999999",
    [NamesOfFieldEnum.ClientFrom]: "999999",
    [NamesOfFieldEnum.ClientPromocode]: "promocode999",
    [NamesOfFieldEnum.ClientLengthOfService]: "greater_than_year",
    [NamesOfFieldEnum.CrmPhone]: "79819820406",
    [NamesOfFieldEnum.LprPhone]: "79819820406",
    [NamesOfFieldEnum.BuhPhone]: "79819820406",
    [NamesOfFieldEnum.TechPhone]: "79819820406",
    [NamesOfFieldEnum.PayformPhone]: "79819820406",
    [NamesOfFieldEnum.PayformLinkVk]: "test link",
    [NamesOfFieldEnum.PayformPaymentMethods]: ["card_rf"],
    
  }
};
