import { ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Field, useFormState, useForm } from "react-final-form";
import { Button, Select, Input, Space, InputRef } from "antd";
import {
  InstagramOutlined,
  GlobalOutlined,
  DeleteOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { DescriptionCollapseField, ErrorField, Icon, TitleField } from "~/components";
import { PayformLinkType, NamesOfFieldEnum } from "~/typings/types";
import { extractExistingFields } from "~/utils/extractExistingFields";
import { composeValidators, disabledValidate, validateDependentFields } from "~/utils";

import styles from "./SocialLinkSelectField.module.scss";
import './styles.scss';
import { ERROR_FIELD_ } from "~/configs/consts";

const { Option } = Select;

const configPlatform: Record<PayformLinkType, PlatformConfig> = {
  [NamesOfFieldEnum.PayformLinkVk]: {
    title: "ВКонтакте",
    icon: <Icon name="vk" className={styles.iconSocial} />,
  },
  [NamesOfFieldEnum.PayformLinkTg]: {
    title: "Telegram",
    icon: <Icon name="tg" className={cn(styles.iconSocial, styles.iconTg)} />,
  },
  [NamesOfFieldEnum.PayformLinkInstagram]: {
    title: "Instagram",
    icon: <InstagramOutlined />,
  },
  [NamesOfFieldEnum.PayformLinkOther]: {
    title: "Сайт",
    icon: <GlobalOutlined />,
  },
  [NamesOfFieldEnum.PayformLinkSite]: {
    title: "Другое",
    icon: <AppstoreOutlined style={{ marginTop: 4 }} />,
  },
};

interface PlatformConfig {
  title: string;
  icon: React.ReactNode;
}

interface SocialField {
  platform: PayformLinkType;
  link: string;
}

interface SocialLinkSelectField {
  name: string;
  titleField?: string;
  orderNumber: string;
  fieldsLinks: string[]; // поля которые нужно отправлять/получать на бек
  descriptionContent?: ReactNode;
}

export const SocialLinkSelectField = ({
  orderNumber,
  name,
  titleField,
  fieldsLinks,
  descriptionContent,
  ...props
}: SocialLinkSelectField) => {
  const [socialFields, setSocialFields] = useState<SocialField[]>([]);
  const [isFocusedSelect, setIsFocusedSelect] = useState(false);
  const inputRefs = useRef<Record<PayformLinkType, InputRef | null>>(
    Object.keys(NamesOfFieldEnum).reduce((acc, key) => {
      acc[key as PayformLinkType] = null;
      return acc;
    }, {} as Record<PayformLinkType, InputRef | null>)
  );

  const form = useForm();
  const formState = useFormState();
  const initValues = formState?.values;

  useEffect(() => {
    const backendDataByFieldsLinks = extractExistingFields(fieldsLinks, initValues);

    const prefilledFields: SocialField[] = Object.entries(backendDataByFieldsLinks)
      .map(([platform, value]) => {
        if (platform && value) {
          setIsFocusedSelect(true);
          return {
            platform,
            link: value as string,
          };
        }
        return null;
      })
      .filter(Boolean) as SocialField[];

    setSocialFields(prefilledFields);
  }, []);

  const handleAddField = (platform: PayformLinkType) => {
    if (typeof platform === "string") {
      const newField: SocialField = {
        platform,
        link: "",
      };
      setSocialFields((prevFields) => [...prevFields, newField]);
      setIsFocusedSelect(true);

      setTimeout(() => {
        inputRefs?.current && inputRefs.current[platform]?.focus();
      }, 0);
    }
  };

  const handleRemoveField = (platform: PayformLinkType) => {
    setSocialFields(socialFields.filter((field) => field.platform !== platform));
    form.change(platform, undefined);
  };

  return (
    <>
      {titleField && <TitleField
        name={titleField}
        required
        tooltip="Интернет-ресурс обязательно должен быть открытым и доступным. Также он должен содержать описание услуг или продуктов, за которые вы планируете принимать оплату через Prodamus. Если ресурсов несколько, укажите их через запятую"
      />}

      <Field
        orderNumber={orderNumber}
        name="payform-link-select"
        subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
        validate={disabledValidate(
          composeValidators(
            validateDependentFields(
              fieldsLinks,
              "Один из источников должен быть обязательно заполнен"
            )
          )
        )}
      >
        {({ input, meta: { submitFailed, error, submitError } }) => {
          const hasError = (error || submitError) && submitFailed;
          const inputId = hasError ? `${ERROR_FIELD_ + orderNumber}` : undefined;

          return (
            <Select
              id={inputId}
              className="custom-select"
              value={null}
              placeholder="Добавить"
              placement="bottomLeft"
              style={{ width: "100%" }}
              onChange={(value: PayformLinkType) => handleAddField(value)}
              dropdownRender={(menu) => {
                return (
                  <>
                    {menu}
                    <Space style={{ padding: "10px", justifyContent: "center" }}>
                      <span style={{ fontSize: "12px", color: "#8c8c8c" }}>
                        Добавьте ссылку для выбранной соцсети
                      </span>
                    </Space>
                  </>
                )
              }}
            >
              {Object.entries(configPlatform)
                .map(([platformKey, config]) => {
                  const isAdded = socialFields.some(
                    (field) => field.platform === platformKey
                  );

                  return (
                    <Option key={platformKey} value={platformKey} disabled={isAdded}>
                      <Space>
                        {config.icon}
                        {config.title}
                      </Space>
                    </Option>
                  );
                })}
            </Select>
          )
        }}
      </Field>
      <ErrorField name="payform-link-select" classNameContainer={styles.containerErrorField} />

      {socialFields?.length ? (
        <div style={{ marginTop: 10 }}>
          {socialFields.map((field) => (
            <Field
              key={field.platform}
              orderNumber={orderNumber}
              name={field.platform}
              initialValue={field.link}
              subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
              validate={disabledValidate(
                composeValidators(
                  validateDependentFields(
                    fieldsLinks,
                    "Один из источников должен быть обязательно заполнен"
                  )
                )
              )}
              render={({ input, meta: { submitFailed, error, submitError } }) => {
                const hasError = (error || submitError) && submitFailed;
                const inputId = hasError ? `${ERROR_FIELD_ + orderNumber}` : undefined;

                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    <Space>{configPlatform[field.platform].icon}</Space>
                    <Input
                      {...input}
                      id={inputId}
                      ref={(el) => (inputRefs.current[field.platform] = el)} // Реф на компонент Antd Input
                      className={cn(styles.inputCustom, {
                        [styles.inputCustomError]: hasError,
                      })}
                      placeholder={`Введите ссылку для ${configPlatform[field.platform].title}`}
                      style={{ flex: 1 }}
                    />
                    <Button
                      danger
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveField(field.platform)}
                    />
                  </div>
                );
              }}
            />
          ))}
        </div>
      ) : null}

      <DescriptionCollapseField content={descriptionContent} trigger={isFocusedSelect} />

    </>
  );
};
