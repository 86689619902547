import { Icon } from "../icon";
import styles from "./TitlePage.module.scss";

import S from '~/assets/img/happy-emoji.jpg'

interface TitlePage {
  name: string;
  number: string;
  description?: string;
  isRenderIconHappy?: boolean;
}

export const TitlePage = ({ name, number, description, isRenderIconHappy = false }: TitlePage) => (
  <div className={styles.container}>
    <p className={styles.number}>{number}</p>
    <div className={styles.containerTitle}>
      <h2 className={styles.name}>
        <span>{name}</span>
        {isRenderIconHappy && <img src={S} alt="" className={styles.iconHappyEmoji} />}
      </h2>
      {description && (
        <p className={styles.description}>{description}</p>
      )}
    </div>
  </div>
);
