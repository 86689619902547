import { ConfigProvider } from "antd";
import { ReactNode } from "react";
import ruRU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

interface LocaleContainerProps {
	children: ReactNode;
}

export const LocaleContainer: React.FC<LocaleContainerProps> = ({ children }) => {
	return (
		<ConfigProvider locale={ruRU}>
			{children}
		</ConfigProvider>
	);
};